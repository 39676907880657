import * as PIXI from 'pixi.js'
import Audio from './Audio'
import Hist from '../utils/Hist'

const SLIP_DURATION = 1000

export default class Felix extends PIXI.Container {
    constructor(engine) {
        super()

        this.spine = new PIXI.spine.Spine(PIXI.loader.resources.felixSpine.spineData)
        this.spine.skeleton.setToSetupPose()
        this.spine.update(0)
        this.spine.autoUpdate = false
        this.spine.stateData.setMix('run', 'jump', 0.4)
        this.spine.stateData.setMix('jump', 'run', 0.1)
        this.spine.stateData.setMix('slip', 'run', 0.5)
        this.spine.stateData.setMix('run', 'slip', 0.1)
        this.spine.stateData.setMix('run', 'fall', 0.1)

        this.spineBounds = this.spine.getLocalBounds()

        this.engine = engine

        this.position.set(200, Math.round(915 - this.spineBounds.height/2))
        this.run()

        this.accel = 1
        this.gravity = 0.08

        this._platform = null
        this.ground = this.position.y
        this.defaultGround = this.position.y

        this.isJumping = false
        this.isLanding = false
        this.isSlipping = false
        this.isFalling = false
        this.slipTimer = null

        this.baseSpeed = 24
        this.speed = this.baseSpeed

        // this.addChild(this.boundingRectangle)
        this.addChild(this.spine)

        // this.collisionBox = this.createCollisionBox()
        // this.addChild(this.collisionBox)
    }

    get platform() {
        return this._platform
    }

    set platform(platform) {

        if (!platform && this._platform && !this.isJumping) {
            this.isJumping = true
            this.accel = 0
        }

        this._platform = platform

        if (this._platform) {
            this.ground = this._platform.box.y - this.spineBounds.height/2
        } else {
            this.ground = this.defaultGround
        }
    }

    get box() {
        return {
            x: this.position.x - this.spineBounds.width/2,
            y: this.position.y - this.spineBounds.height/2,
            width: this.spineBounds.width,
            height: this.spineBounds.height
        }
    }

    reset() {
        this.spine.state.clearTrack(0)
        this.spine.skeleton.setToSetupPose()
        this.spine.update(0)
        this.isFalling = false
        this.isJumping = false
        this.isSlipping = false
        this.position.set(200, Math.round(915 - this.spineBounds.height / 2))
        this.run()

        if (this.collisionBox) {
            this.removeChild(this.collisionBox)
            this.collisionBox = this.createCollisionBox()
            this.addChild(this.collisionBox)
        }
    }

    jump(origin) {
        if (this.isJumping || this.isSlipping || this.isFalling) {
            return
        }
        Hist.push({
            type: 'felix:jump',
            data: {
                origin,
                position: this.engine.gameDelta
            }
        })
        this.isJumping = true
        this.accel = 1.9
        this.ground = this.position.y
        Audio.play('jump')
        this.spine.state.setAnimation(0, 'jump', false)
    }

    run() {
        this.spine.state.setAnimation(0, 'run', true)
    }

    slip() {
        Hist.push({
            type: 'felix:slip',
            data: {
                position: this.engine.gameDelta
            }
        })
        clearTimeout(this.slipTimer)
        this.isSlipping = true
        this.spine.state.setAnimation(0, 'slip', false)
        Audio.play('slip')

        this.slipTimer = setTimeout(() => {
            this.isSlipping = false
            this.run()
        }, SLIP_DURATION)
    }

    fall() {
        Hist.push({
            type: 'felix:fall',
            data: {
                position: this.engine.gameDelta
            }
        })
        clearTimeout(this.slipTimer)
        this.accel = 0
        this.isFalling = true
        Audio.play('die')
        this.spine.state.setAnimation(0, 'fall', false)
    }

    die() {
        Hist.push({
            type: 'felix:die',
            data: {
                position: this.engine.gameDelta
            }
        })
        clearTimeout(this.slipTimer)
        Audio.play('die')
        this.engine.gameOver()
    }

    update(delta) {
        this.spine.update(delta)

        if (!this.isJumping && !this.isFalling) {
            return
        }

        let position = this.position.y

        if (this.isJumping) {
            this.accel -= this.gravity
            position -= this.accel * this.speed

            if (position > this.ground) {
                this.isJumping = false
                position = this.ground
                this.run()
            }
        }

        if (this.isFalling) {
            this.accel += this.gravity
            position += this.accel * delta * this.speed / 5

            if (position - this.spineBounds.height > 1080) {
                this.engine.gameOver()
            }
        }

        this.position.y = position
    }

    setSpeed(speed) {
        this.spine.state.timeScale = 1 / 32 * speed / this.engine.baseSpeed
    }

    headCollision() {
        if (!this.isJumping) {
            return
        }

        if (this.accel > 0) {
            this.accel = -0.5
        }
    }

    createCollisionBox() {
        const box = this.box
        const x = box.x - this.position.x
        const y = box.y - this.position.y

        const collisionBox = new PIXI.Graphics()
        collisionBox.lineStyle(2, 0xff3300)
        collisionBox.moveTo(x, y)
        collisionBox.lineTo(x + box.width, y)
        collisionBox.lineTo(x + box.width, y + box.height)
        collisionBox.lineTo(x, y + box.height)
        collisionBox.lineTo(x, y)

        return collisionBox
    }
}