import * as PIXI from 'pixi.js'


const _defaults = {
    width: 0,
    height: 174
}
export default class Ground extends PIXI.Container {

    constructor(options = {}) {
        super()

        this.options = {
            ..._defaults,
            ...options
        }

        this.width = this.options.width
        this.height = this.options.height

        this.top = new PIXI.Graphics()
        this.top.width = this.options.width
        this.top.height = 13
        this.top.beginFill(0xF6FAFF)
        this.top.drawRect(0, 0, this.options.width, 13)
        this.top.position.set(0,0)

        this.bottom = new PIXI.Graphics()
        this.bottom.width = this.options.width
        this.bottom.height = this.options.height - 13
        this.bottom.beginFill(0xE6EEF5)
        this.bottom.drawRect(0, 0, this.options.width, this.options.height - 13)
        this.bottom.position.set(0, 13)

        this.addChild(this.top)
        this.addChild(this.bottom)
    }

    resize({ width, height}) {
        this.top.width = width
        this.bottom.width = width
    }
}