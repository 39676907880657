// set basepath of project for webpack
import { basePath } from './core/config';
__webpack_public_path__ = `${basePath}/assets/frontend/js/`;

import 'console-polyfill';
import elementClosestPolyfill from 'element-closest'
elementClosestPolyfill(window)

import $ from 'jquery';

// jquery utils
import 'jquery.easing';

import './plugins/nette.ajax';
import './plugins/giant.trigger';
import './plugins/giant.autosubmit';

// nette
import './nette/NetteValidationMessages';
import './nette/NetteSnippets';

import EventBus from './core/EventBus'

import Nette from './nette/NetteForms';
window.Nette = Nette;
Nette.initOnLoad();

if ($.nette) {
    $.nette.init();
}

try {
    [].includes()
    Object.assing()
} catch (e) {}


import ScrollService from './services/Scroll'
ScrollService.useNative()

import TouchMouseInputResolver from './meta/TouchMouseInputResolver'
new TouchMouseInputResolver()

import Component from './core/Component'
import { loadComponents } from './core/componentUtils'

loadComponents()

try {
    sessionStorage.setItem('showManual', true)
} catch (e) {

}

import Game from '../../game/js/game.js'

const layerScore = Component.getFromElement('layer-score')

const container = document.getElementById('game-container')
const game = new Game(container)
game.on('game:over', data => {
    game.stop()
    EventBus.emit('game:over', data)
    layerScore.open()
    document.documentElement.classList.remove('is-game-active')

    // gtm
    if (dataLayer) {
        dataLayer.push({
            'event':'konec-hry',
            'skore': data.total,
            'mince-bronz': data.coinBronze,
            'mince-stribro': data.coinSilver,
            'mince-zlato': data.coinGold,
            'mince-cihla': data.brick
        });
    }
})

game.on({
    'game:start': () => EventBus.emit('game:start'),
    'game:stop': () => EventBus.emit('game:stop'),
    'game:levelup': data => EventBus.emit('game:levelup', data)
})

EventBus.on('game:show', () => {
    if (dataLayer) {
        dataLayer.push({
            'event':'VirtualPageview',
            'virtualPageURL':'/hra',
            'virtualPageTitle' : 'Felix Runner - Hra'
        })
    }

    game.reset().show().start()
})

EventBus.on('game:start', () => {
    document.documentElement.classList.add('is-game-active')
})

EventBus.on('game:pause', () => game.pause())
EventBus.on('game:resume', () => {
    setTimeout(() => game.resume(), 100)
})

EventBus.on('game:reset', () => {
    layerScore.close()
    game.reset().show().start()
})

// EventBus.emit('game:show')

$.nette.ext('scoreForm', {
    success: payload => {
        if (payload?.component?.name === 'scoreForm' && payload?.component?.status === 'success') {
            if (dataLayer) {
                dataLayer.push({
                    event: 'registrace-skore'
                })
            }

            const modal = Component.getFromElement('zapis')

            if (modal) {
                modal.close()
            }

            const top10modal = Component.getFromElement('top10')

            if (top10modal) {
                top10modal.open()
            }

            const scoreForm = Component.getFromElement('score-form-component')

            if (scoreForm) {
                scoreForm.reset()
            }
        }
    }
})