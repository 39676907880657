import * as PIXI from 'pixi.js'
import Scene from './Scene'
import { default as SceneDefinitions } from '../constants/scenes'
import STAGE from '../constants/stage'
import SCENE from '../constants/sceneTypes'
import ENTRANCE from '../constants/entranceTypes'
import { randomInteger, weightedRandom } from '../utils/Math'
import sample from 'lodash/sample'
import sum from 'lodash/sum'
import Hist from '../utils/Hist'

// const _defaultPreset = ['livingRoomYellow', 'kitchenWooden', 'bathRoom', 'childRoom', 'bedRoom', 'rsts']
// const _defaultPreset = ['childRoom', 'bedRoom', 'rsts']
const _defaultPreset = []

export default class SceneManager extends PIXI.Container {
    constructor(engine) {
        super()

        this.engine = engine

        this.scenes = []

        this.pool = Object.keys(SceneDefinitions)
            .map(key => ({
                sceneId: key,
                active: false,
                weight: SceneDefinitions[key].type === SCENE.OUTDOOR ? 8 : 5,
                type: SceneDefinitions[key].type
            }))
            .reduce((acc, item) => {
                acc[item.sceneId] = item
                return acc
            }, {})

        this.poolArray = Object.keys(this.pool).filter(key => key !== 'rsts').map(key => this.pool[key])
        this.sceneCounter = 0
        this.canGenerateBonusScene = false

        this.cache = {}

        this.reset()

        this.engine.view.background.addChild(this)
    }

    update(delta) {
        let position = Math.abs(this.position.x)

        for (let i = 0; i < this.scenes.length; i++) {
            this.cache[this.scenes[i]].update({
                speed: this.engine.speed,
                delta
            })

            if (this.scenes[i] === 'rsts') {
                if (
                    this.cache[this.scenes[i]].position.x - position < this.engine.app.screen.width + 200
                    && this.cache[this.scenes[i]].position.x - position + this.cache[this.scenes[i]].width > this.engine.app.screen.width / 2 + 200
                ) {
                    this.engine.bonusRoundStart()
                } else {
                    this.engine.bonusRoundEnd()
                }
            }
        }

        if (position > this.currentSceneWidth) {
            position = position - this.currentSceneWidth
            const disposed = this.scenes.shift()
            this.removeScene(disposed)
            this.scenes.forEach(sceneId => this.cache[sceneId].position.x -= this.currentSceneWidth)
            this.currentSceneWidth = this.cache[this.scenes[0]].width

            if (this.scenes.length < 3) {
                this.addScene()
            }
        }

        this.position.x = -1 * (position + delta * this.engine.speed * 0.5)
    }

    pickScene(type = null) {
        this.sceneCounter++
        const factor = (2 + this.engine.speedFactor) / 3

        if (this.preset.length) {
            return this.preset.shift()
        }

        let poolArray = this.poolArray.filter(item => !item.active)

        // forced type pick
        if (type) {
            const item = weightedRandom(poolArray.filter(item => item.type === type))
            return item.sceneId
        }

        // try to generate bonus round every 5 rooms
        if (this.canGenerateBonusScene && (this.sceneCounter > Math.floor(5 * factor))) {
            this.sceneCounter = 0
            this.canGenerateBonusScene = false
            return 'rsts'
        }

        // if last scene is outdoor, we have to pick indoor
        const lastSceneId = this.scenes[this.scenes.length - 1]
        if (lastSceneId === 'countrysideAfter' || this.pool[lastSceneId]?.type === SCENE.OUTDOOR) {
            poolArray = poolArray.filter(item => item.type === SCENE.INDOOR)
        }

        // if last scene is indoor, we have to pick outdoor
        if (this.pool[lastSceneId]?.type === SCENE.INDOOR) {
            poolArray = poolArray.filter(item => item.type === SCENE.OUTDOOR)
        }

        const item = weightedRandom(poolArray)
        return item.sceneId
    }

    getScene(sceneId) {
        if (!(sceneId in this.cache)) {
            this.cache[sceneId] = new Scene(SceneDefinitions[sceneId], {
                width: SceneDefinitions[sceneId].baseWidth,
                height: 908
            })
        }

        return this.cache[sceneId]
    }

    addScene(type = null) {
        const sceneId = this.pickScene(type)
        const lastSceneId = this.scenes[this.scenes.length - 1]
        this.pool[sceneId].active = true

        const scene = this.getScene(sceneId)

        let offset = sum(this.scenes.map(sceneId => this.cache[sceneId].width))

        // inject outdoor scene before rsts if not already available
        if (sceneId === 'rsts' && this.pool[lastSceneId].type !== SCENE.OUTDOOR) {
            const outdoorSceneId = this.pickScene(SCENE.OUTDOOR)
            const outdoorScene = this.getScene(outdoorSceneId)
            outdoorScene.position.x = offset
            outdoorScene.hasEntrance = ENTRANCE.WOODEN
            this.scenes.push(outdoorSceneId)

            Hist.push({
                type: 'scene:add',
                data: {
                    type: outdoorSceneId,
                    x: offset
                }
            })

            offset += outdoorScene.width
            this.addChild(outdoorScene)
        }

        scene.position.x = offset

        this.addChild(scene)
        this.scenes.push(sceneId)

        Hist.push({
            type: 'scene:add',
            data: {
                type: sceneId,
                x: offset,
                ...this.cache[sceneId].getData()
            }
        })

        if (sceneId === 'rsts') {
            offset += scene.width
            const outdoorSceneId = this.pickScene(SCENE.OUTDOOR)
            const outdoorScene = this.getScene(outdoorSceneId)
            outdoorScene.position.x = offset
            outdoorScene.hasEntrance = ENTRANCE.MODERN
            this.scenes.push(outdoorSceneId)

            Hist.push({
                type: 'scene:add',
                data: {
                    type: outdoorSceneId,
                    x: offset
                }
            })
            this.addChild(outdoorScene)
        }

        if (this.pool[sceneId].type === SCENE.OUTDOOR) {
            scene.hasEntrance = ENTRANCE.WOODEN
        } else if (lastSceneId && this.pool[lastSceneId].type === SCENE.OUTDOOR) {
            scene.hasEntrance = ENTRANCE.WOODEN
        } else {
            scene.hasEntrance = ENTRANCE.NONE
        }
    }

    removeScene(sceneId) {
        this.removeChild(this.cache[sceneId])
        this.pool[sceneId].active = false

        if (sceneId === 'rsts') {
            this.sceneCounter = 0
            this.canGenerateBonusScene = true
        }

        this.cache[sceneId].reset()
    }

    reset() {
        const scenes = [...this.scenes]
        scenes.forEach(sceneId => this.removeScene(sceneId))

        this.canGenerateBonusScene = false
        this.sceneCounter = 0

        this.scenes = []
        this.preset = [..._defaultPreset]

        this.addScene(SCENE.OUTDOOR) // force outdoor scene as first
        this.addScene()
        this.addScene()

        this.currentSceneWidth = this.cache[this.scenes[0]].width
    }
}