import * as PIXI from 'pixi.js'
import * as particles from 'pixi-particles'
import STAGE from '../constants/stage'

const BASE_WIDTH = 1920
const BASE_HEIGHT = 1080

const emitterOptions = {
    "alpha": {
        "start": 1,
        "end": 1
    },
    "scale": {
        "start": 0.1,
        "end": 0.11, // same value has no effect on scale multiplier
        "minimumScaleMultiplier": 3
    },
    "color": {
        "start": "#ffffff",
        "end": "#ffffff"
    },
    "speed": {
        "start": 50,
        "end": 100,
        "minimumSpeedMultiplier": 2
    },
    "acceleration": {
        "x": -4,
        "y": 4
    },
    "maxSpeed": 0,
    "startRotation": {
        "min": 45,
        "max": 120
    },
    "noRotation": false,
    "rotationSpeed": {
        "min": 0,
        "max": 0
    },
    "lifetime": {
        "min": 10,
        "max": 10
    },
    "blendMode": "normal",
    "frequency": 0.1,
    "emitterLifetime": -1,
    "maxParticles": 500,
    "pos": {
        "x": 0,
        "y": 0
    },
    "addAtBack": false,
    "spawnType": "rect",
    "spawnRect": {
        "x": 0,
        "y": 0,
        "w": 0,
        "h": 0
    }
}

export default class Snow extends PIXI.Container {
    constructor(engine, options) {
        super()

        this.options = options
        this.engine = engine

        this.position.set(0, 0)

        this.ellapsed = Date.now()

        this.engine.on('game:playing:start', this.start)
        this.engine.on('game:playing:stop', this.stop)

        emitterOptions.spawnRect.w = this.options.width

        this.width = this.options.width
        this.height = this.options.height

        this.emitter = new particles.Emitter(this, [PIXI.Texture.from('snow-dot'), PIXI.Texture.from('snow-flake')], emitterOptions)
    }

    update() {
        const now = Date.now()
        this.emitter.update((now - this.ellapsed) * 0.001)
        this.ellapsed = now
    }

    resize({ width, height }) {
        const scale = height / BASE_HEIGHT

        this.options.width = width / scale
        this.options.height = height

        this.width = this.options.width
        this.height = this.options.height

        this.emitter.spawnRect.width = this.options.width
    }

    start = () => {
        this.ellapsed = Date.now()
        this.emitter.emit = true
    }

    stop = () => {
        this.emitter.emit = false
        this.emitter.cleanup()
    }
}