const EventDispatcher = {

    /**
     *  normalise event names
     */
    normalize(eventName) {
        return eventName.replace(':', '');
    },

    /**
     *  list of events callbacks
     */
    listeners: [],

    /**
     *  add callback to a gloabl event chanel
     */
    on(eventName, fn, target = null) {
        var eventName = this.normalize(eventName);
        this.listeners.push({ name: eventName, closure: fn, target: target });
    },

    /**
     *  remove callback to a global event chanel
     */
    off(eventName, fn) {

        var evt,
            i = 0,
            l = this.listeners.length,
            eventName = this.normalize(eventName);

        this.listeners = this.listeners.filter(event => !(event.name === eventName && event.closure === fn))

        // for (; i < l; i++) {
        //     evt = this.listeners[i];
        //     if (evt.name === eventName && evt.closure === fn) {
        //         this.listeners.splice(i, 1);
        //         i--;
        //         l--;
        //     }
        // }
    },

    /**
     *  trigger callbacks from a global event chanel
     */
    emit(eventName, params) {
        var evt,
            i = 0,
            l = this.listeners.length,
            eventName = this.normalize(eventName);

        for (; i < l; i++) {
            evt = this.listeners[i];
            if (!evt) continue;
            if (evt.name === eventName) {
                if (typeof params != "undefined") {
                    if (!Array.isArray(params)) {
                        params = [params]
                    }
                    evt.closure.apply(evt.target, params);
                } else {
                    evt.closure.call(evt.target);
                }
            }
        }
    },

    /**
     *  list of request callbacks
     */
    handlers: {},

    /**
     * create global request chanel handler
     */
    setHandler(handlerName, handler) {
        this.handlers[this.normalize(handlerName)] = handler;
    },

    /**
     * trigger global request chanel
     */
    request(handlerName, params) {
        var handlerName = this.normalize(handlerName);
        if (typeof this.handlers[handlerName] != 'undefined') {
            return typeof params != "undefined" ? this.handlers[handlerName].apply(this, params) : this.handlers[handlerName]();
        } else {
            return { error: 'request error :: no handler found', handler: handlerName };
        }
    }

};

export default EventDispatcher;