// general
import Antispam from '../../components/Antispam'
// import CookieBubble from '../../components/CookieBubble'

// project
import GameOpener from './GameOpener'
import HelpLayer from './HelpLayer'
import Layer from './Layer'
import LayerOrientation from './LayerOrientation'
import Modal from './Modal'
import ModalOpener from './ModalOpener'
import Nav from './Nav'
import NavOpener from './NavOpener'
import ScoreForm from './ScoreForm'
import ScoreLayer from './ScoreLayer'

const Components = {
    Antispam,
    // CookieBubble,
    GameOpener,
    HelpLayer,
    Layer,
    LayerOrientation,
    Modal,
    ModalOpener,
    Nav,
    NavOpener,
    ScoreForm,
    ScoreLayer
}

export default Components