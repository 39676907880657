import Component from '../core/Component'

export default class NavOpener extends Component {

    static OPEN = 'is-open';

    prepare() {
        this.element.addEventListener('click', this.handleClick)

        this.nav = Component.getFromElement('nav')

        if (this.nav) {
            this.nav.on({
                open: this.handleNavOpen,
                close: this.handleNavClose
            })
        }
    }

    handleNavOpen = () => {
        this.element.classList.add(NavOpener.OPEN)
    }

    handleNavClose = () => {
        this.element.classList.remove(NavOpener.OPEN)
    }

    handleClick = event => {
        event.preventDefault()

        if (this.nav) {
            this.nav.toggle()
        }
    }
}