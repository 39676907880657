import * as PIXI from 'pixi.js'
import { weightedRandom } from '../utils/Math'

const enemies = {
    'banana': {
        type: 'slide',
        weight: 10,
        id: 'banana',
        texture: null,
        ground: true,
        platform: true,
        offset: {
            x: 0,
            y: -28
        }
    },
    'oil': {
        type: 'slide',
        weight: 5,
        id: 'oil',
        texture: null,
        ground: true,
        platform: false,
        offset: {
            x: 0,
            y: 60
        }
    },
    'oil-platform': {
        type: 'slide',
        weight: 5,
        id: 'oil-platform',
        texture: null,
        ground: false,
        platform: true,
        offset: {
            x: 0,
            y: 28
        }
    },
    'shards-primary': {
        type: 'die',
        weight: 5,
        id: 'shards-primary',
        texture: null,
        ground: true,
        platform: true,
        offset: {
            x: 0,
            y: -10
        }
    },
    'shards-secondary': {
        type: 'die',
        weight: 5,
        id: 'shards-secondary',
        texture: null,
        ground: true,
        platform: true,
        offset: {
            x: 0,
            y: -10
        }
    },
    'paint': {
        type: 'die',
        weight: 10,
        id: 'paint',
        texture: null,
        platform: true,
        ground: true,
        offset: {
            x: 0,
            y: -38
        }
    },
    'stairs': {
        type: 'fall',
        weight: 5,
        id: 'stairs',
        texture: null,
        platform: false,
        ground: true,
        offset: {
            x: 0,
            y: 98
        }
    }
}

const enemyList = Object.keys(enemies).map(key => enemies[key])

export default class Enemy extends PIXI.Container {
    constructor() {
        super()

        const type = this.getType()

        this.tollerance = 5

        this.sprite = new PIXI.Sprite(type.texture)
        this.sprite.anchor.set(0.5, 0.5)

        this.hasCollided = false
        this.type = type.id
        this.action = type.type
        this.offset = type.offset

        this.addChild(this.sprite)

        // this.collisionBox = this.createCollisionBox()
        // this.addChild(this.collisionBox)
    }

    get box() {
        return {
            x: this.position.x - this.width/2 + this.tollerance,
            y: this.position.y - this.height/2 + this.tollerance,
            width: this.width - (this.type === 'stairs' ? 60 + this.tollerance : this.tollerance * 2),
            height: this.height - this.tollerance * 2
        }
    }

    createCollisionBox() {
        const box = this.box
        const x = box.x - this.position.x
        const y = box.y - this.position.y

        const collisionBox = new PIXI.Graphics()
        collisionBox.lineStyle(2, 0xff3300)
        collisionBox.moveTo(x, y)
        collisionBox.lineTo(x + box.width, y)
        collisionBox.lineTo(x + box.width, y + box.height)
        collisionBox.lineTo(x, y + box.height)
        collisionBox.lineTo(x, y)

        return collisionBox
    }

    update() {}

    reset() {
        const type = this.getType()
        this.hasCollided = false
        this.sprite.texture = type.texture
        this.offset = type.offset
        this.type = type.id
        this.action = type.type

        if (this.collisionBox) {
            this.removeChild(this.collisionBox)
            this.collisionBox = this.createCollisionBox()
            this.addChild(this.collisionBox)
        }
    }

    getType() {
        const type = weightedRandom(enemyList.filter(item => item.ground))

        if (!type.texture) {
            type.texture = PIXI.Texture.from(type.id)
        }

        return type
    }
}