import * as PIXI from 'pixi.js'
import STAGE from '../constants/stage'

export default class Leaderboard extends PIXI.Container {

    constructor(engine) {
        super()

        this.engine = engine
        this.isUpdating = false

        this.styleText = new PIXI.TextStyle({
            dropShadow: false,
            fill: 0x4f5d6a,
            fontFamily: ['DINRounded-webfont', 'Arial', 'Helvetica'],
            fontSize: 20,
            letterSpacing: 0,
            lineHeight: 1,
            fontWeight: 500
        })

        this.stylePosition = new PIXI.TextStyle({
            dropShadow: false,
            fill: 0xffffff,
            fontFamily: ['DINRounded-webfont', 'Arial', 'Helvetica'],
            fontSize: 30,
            letterSpacing: -2,
            lineHeight: 1,
            fontWeight: 500
        })

        this.plate = new PIXI.Sprite(PIXI.Texture.from('leaderboard-plate'))
        this.plate.anchor.set(0.5, 0.5)

        this.textBefore = new PIXI.Text('Právě jsi na', this.styleText)
        this.textBefore.anchor.set(1, 0.5)
        this.textBefore.position.set(this.plate.position.x - this.plate.width / 2 - 20, this.plate.position.y)

        this.textAfter = new PIXI.Text('místě. Utíkej!', this.styleText)
        this.textAfter.anchor.set(0, 0.5)
        this.textAfter.position.set(this.plate.position.x + this.plate.width / 2 + 20, this.plate.position.y)

        this.textPosition = new PIXI.Text(this.engine.leaderboardPosition, this.stylePosition)
        this.textPosition.anchor.set(0.5, 0.5)
        this.textPosition.position.set(this.plate.position.x, this.plate.position.y)

        this.addChild(this.plate)
        this.addChild(this.textBefore)
        this.addChild(this.textAfter)
        this.addChild(this.textPosition)

        this.position.set(this.engine.app.view.width / (this.engine.app.view.height / STAGE.CANVAS_HEIGHT) / 2, STAGE.CANVAS_HEIGHT - STAGE.GROUND_HEIGHT / 2)
    }

    resize() {
        const x = this.engine.app.view.width / (this.engine.app.view.height / STAGE.CANVAS_HEIGHT) / 2
        const y = STAGE.CANVAS_HEIGHT - STAGE.GROUND_HEIGHT / 2
        this.position.set(x, y)
    }

    update(position) {
        this.textPosition.text = `${position}.`
        const scale = Math.min(1, 40 / (this.textPosition.width / this.textPosition.scale.x))
        this.textPosition.scale.set(scale)
    }

    reset() {
        this.textPosition.scale.set(1)
        this.update(this.engine.leaderboardPosition)
    }

}