import { Howl } from 'howler'
import Emitter from '../utils/Emitter'
import config from '../constants/config'

const _sounds = {
    jump: 'jump',
    pick: 'coin',
    die: 'die',
    slip: 'slide',
    music: 'music',
    count1: 'cink1',
    count2: 'cink2'
}

class Audio extends Emitter {
    static MUTED = 0;
    static LOW = 1;
    static MEDIUM = 2;
    static LOUD = 3;

    constructor() {
        super()

        this.level = Audio.LOUD

        this.MUTED = Audio.MUTED
        this.LOW = Audio.LOW
        this.MEDIUM = Audio.MEDIUM
        this.LOUD = Audio.LOUD

        this.sounds = Object.keys(_sounds)
            .reduce((acc, key) => {
                acc[key] = new Howl({
                    src: [
                        `${config.assetFolder}/audio/${_sounds[key]}.webm`,
                        `${config.assetFolder}/audio/${_sounds[key]}.mp4`,
                        `${config.assetFolder}/audio/${_sounds[key]}.ogg`,
                        `${config.assetFolder}/audio/${_sounds[key]}.mp3`
                    ],
                    loop: key === 'music',
                    volume: key === 'music' ? 0.5 : 1
                })

                return acc
            }, {})
    }

    play(soundId) {
        if (this.level === Audio.MUTED) {
            return
        }

        if (soundId in this.sounds) {
            this.sounds[soundId].stop()
            this.sounds[soundId].play()
        }
    }

    stop(soundId) {
        if (this.level === Audio.MUTED) {
            return
        }

        if (soundId in this.sounds) {
            this.sounds[soundId].stop()
        }
    }

    toggle() {
        switch (this.level) {
            case Audio.MUTED:
                this.level = Audio.LOW
            break;
            case Audio.LOW:
                this.level = Audio.MEDIUM
            break;
            case Audio.MEDIUM:
                this.level = Audio.LOUD
            break;
            case Audio.LOUD:
                this.level = Audio.MUTED
            break;
        }

        this.setVolume(this.level)
    }

    setVolume(level) {
        const volume = level * 0.33

        Object.keys(this.sounds)
            .forEach(key => {
                this.sounds[key].volume(volume)
            })

        this.emit('change', {
            level: level
        })
    }
}

const audio = new Audio()

export default audio