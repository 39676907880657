import { enableScrolling, disableScrolling } from '../Scroll'

const OverlayService = {

    stack: [],

    scrollingDisabled: false,

    push(modal) {
        if (!this.stack.includes(modal)) {
            this.stack.push(modal)
        }

        this._resolve()
    },

    pull(modal) {
        const index = this.stack.indexOf(modal)

        if (index > -1) {
            this.stack.splice(index, 1)
        }

        this._resolve()
    },

    _resolve() {
        if (!this.scrollingDisabled && this.stack.length > 0) {
            disableScrolling()
            this.scrollingDisabled = true
        }

        if (this.scrollingDisabled && this.stack.length === 0) {
            enableScrolling()
            this.scrollingDisabled = false
        }
    },

    closeAll() {
        this.stack.forEach(modal => modal.close())
        this._resolve()
    }
}

export default OverlayService