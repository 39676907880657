import * as PIXI from 'pixi.js'
import sample from 'lodash/sample'
import colors from '../constants/colors'
import PATTERN from '../constants/pattern'

const textures = Object.keys(colors)
    .reduce((acc, key) => {
        const color = colors[key]
        const canvas = document.createElement('canvas')
        canvas.width = 128
        canvas.height = 128
        const context = canvas.getContext('2d')
        const fill = context.createLinearGradient(0, 0, 0, 128)
        fill.addColorStop(0, color.from)
        fill.addColorStop(1, color.to)
        context.fillStyle = fill
        context.fillRect(0, 0, 128, 128)

        acc[key] = canvas
        return acc
    }, {})


export default class SceneBackground extends PIXI.Container {
    constructor(options) {
        super()

        this.options = {
            ...options
        }

        this.width = this.options.width
        this.height = this.options.height
        this.color = null
        this.pattern = null
        this.data = {
            color: null,
            pattern: null
        }

        this.addColor(this.options.defs.color)
        this.addPattern(this.options.defs.pattern)
    }

    addColor(color) {
        if (Array.isArray(color)) {
            color = sample(color)
        }

        if (!(color in colors)) {
            color = sample(Object.keys(colors))
        }

        this.color = color

        const texture = textures[color]

        if (!texture) {
            return
        }

        this.data.color = color

        const sprite = new PIXI.Sprite(PIXI.Texture.from(texture))
        sprite.width = this.options.width
        sprite.height = this.options.height
        this.addChild(sprite)
    }

    addPattern(pattern) {
        if (!pattern) {
            return
        }

        let sprite = null

        if (Array.isArray(pattern)) {
            pattern = sample(pattern)
        }

        if (pattern === PATTERN.RANDOM) {
            pattern = sample(Object.keys(PATTERN).map(key => PATTERN[key]).filter(value => value !== PATTERN.RANDOM))
        }

        switch (pattern) {
            case PATTERN.BRICKS:
                sprite = new PIXI.extras.TilingSprite(PIXI.Texture.from('pattern-bricks'))
            break;
            case PATTERN.STARS:
                sprite = new PIXI.extras.TilingSprite(PIXI.Texture.from('pattern-stars'))
            break;
        }

        if (sprite) {
            sprite.width = this.options.width
            sprite.height = this.options.height
            sprite.alpha = 0.4
            this.addChild(sprite)
        }

        this.data.pattern = pattern
        this.pattern = sprite
    }

    getHexColor() {
        if (this.color) {
            return colors[this.color].hex
        }

        return null
    }

    shuffle() {
        this.removeChildren()
        this.addColor(this.options.defs.color)
        this.addPattern(this.options.defs.pattern)
    }

    getData() {
        return {...this.data}
    }
}