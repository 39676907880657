import camelCase from 'lodash/camelCase'
import SceneManager from './SceneManager'
import PickupManager from './PickupManager'
import EnemyManager from './EnemyManager'
import CollisionManager from './CollisionManager'
import PlatformManager from './PlatformManager'
import LeaderboardManager from './LeaderboardManager'
import Countdown from './Countdown'
import Felix from './FelixSpine'
import Score from './Score'
import Leaderboard from './Leaderboard'
import MainView from './MainView'
import Snow from './Snow'
import enemyTypes from '../constants/enemyTypes'
import Audio from './Audio'
import Emitter from '../utils/Emitter'
import Hist from '../utils/Hist'

export default class Engine extends Emitter {
    constructor(app) {
        super()

        this.app = app

        this.view = new MainView({
            width: app.screen.width,
            height: app.screen.height
        })

        this.baseSpeed = 10
        this.speed = this.baseSpeed
        this.speedFactor = 1
        this.factor = 1.1
        this.score = {
            coinBronze: 0,
            coinSilver: 0,
            coinGold: 0,
            brick: 0,
            total: 0
        }
        this.level = 1
        this.baseLevelDelta = 500
        this.levelDelta = 500
        this.levelCounter = 0

        this.gameDelta = 0

        this.bonusRound = false

        this.snow = new Snow(this, {
            width: app.screen.width,
            height: app.screen.height
        })
        this.view.snow.addChild(this.snow)
        this.felix = new Felix(this)
        this.felix.setSpeed(this.speed)
        this.view.character.addChild(this.felix)
        this.scoreView = new Score(this)
        this.view.overlay.addChild(this.scoreView)
        this.leaderboard = new Leaderboard(this)
        this.view.overlay.addChild(this.leaderboard)
        this.countdown = new Countdown(this)
        this.view.overlay.addChild(this.countdown)
        this.sceneManager = new SceneManager(this)
        this.pickupManager = new PickupManager(this)
        this.enemyManager = new EnemyManager(this)
        this.platformManager = new PlatformManager(this)
        this.collisionManager = new CollisionManager(this)
        this.leaderboardManager = new LeaderboardManager(this)

        this.resize()
    }

    update(delta) {
        this.countdown.update(delta)

        if (!this.countdown.hasFinished) {
            return
        }

        this.gameDelta += delta * this.speed

        this.sceneManager.update(delta)
        this.pickupManager.update(delta)
        this.enemyManager.update(delta)
        this.platformManager.update(delta)
        this.collisionManager.update(delta)
        this.felix.update(delta)
        this.snow.update(delta)

        this.levelCounter += delta
        if (this.levelCounter > this.levelDelta) {
            this.speed *= this.factor
            this.speedFactor = this.speed / this.baseSpeed
            this.levelDelta *= this.factor
            this.levelCounter = 0
            this.level++
            this.felix.setSpeed(this.speed)
            this.emit('game:levelup', {
                ...this.score
            })
            Hist.push({
                type: 'game:levelup',
                data: {
                    level: this.level,
                    speed: this.speed
                }
            })
        }
    }

    reset() {
        this.score = {
            coinBronze: 0,
            coinSilver: 0,
            coinGold: 0,
            brick: 0,
            total: 0
        }
        this.level = 1
        this.levelDelta = this.baseLevelDelta
        this.levelCounter = 0

        this.gameDelta = 0

        this.speed = this.baseSpeed
        this.speedFactor = 1
        this.factor = 1.2

        this.bonusRound = false

        this.pickupManager.destroyAll()
        this.enemyManager.destroyAll()
        this.platformManager.destroyAll()
        this.sceneManager.reset()
        this.leaderboardManager.reset()
        this.felix.reset()
        this.felix.setSpeed(this.speed)
        this.scoreView.update(this.score.total)
        this.countdown.reset()

        Hist.purge()
    }

    resize() {
        this.view.resize({
            width: this.app.view.width / this.app._options.resolution,
            height: this.app.view.height / this.app._options.resolution
        })
        this.countdown.resize()
        this.leaderboard.resize()
        this.snow.resize({
            width: this.app.view.width / this.app._options.resolution,
            height: this.app.view.height / this.app._options.resolution
        })
    }

    pick({type, value}) {
        this.score[camelCase(type)]++
        this.score.total += value
        this.scoreView.update(this.score.total)
        this.leaderboardManager.update(this.score.total)
        Audio.play('pick')
    }

    enemy(type) {
        if (type === enemyTypes.SLIDE) {
            this.felix.slip()
        }

        if (type === enemyTypes.FALL) {
            // console.warn('fall')
            this.felix.fall()
        }

        if (type === enemyTypes.DIE) {
            // console.warn('die')
            this.felix.die()
        }
    }

    bonusRoundStart() {
        if (!this.bonusRound) {
            this.bonusRound = true
            Hist.push({
                type: 'bonusround:start'
            })
            this.emit('game:bonusround:start')
        }
    }

    bonusRoundEnd() {
        if (this.bonusRound) {
            this.bonusRound = false
            Hist.push({
                type: 'bonusround:end'
            })
            this.emit('game:bonusround:end')
        }
    }

    playingStart() {
        Hist.push({
            type: 'game:run:start'
        })
        Audio.play('music')
        this.emit('game:playing:start')
    }

    gameOver() {
        Hist.push({
            type: 'game:over'
        })
        this.app.stop()
        this.leaderboardManager.end()
        this.emit('game:playing:stop')
        this.emit('game:over', {
            ...this.score,
            check: Hist.commit()
        })
    }
}