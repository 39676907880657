export default class ObjectPoll {
    constructor(classType) {
        this.classType = classType
        this.pool = []
    }

    getObject(options = {}) {
        let object = this.pool.pop()

        if (!object) {
            object = new this.classType(options)
        } else {
            object.reset(options)
        }

        return object
    }

    returnObject(object) {
        if (object instanceof this.classType) {
            this.pool.push(object)
        }
    }
}