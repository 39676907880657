import * as PIXI from 'pixi.js'
import Audio from './Audio'

const _defaults = {
    width: 1920,
    height: 1080
}

export default class AudioButton extends PIXI.Container {

    constructor(options = {}) {
        super()

        this.options = {
            ..._defaults,
            ...options
        }

        this.position.set(this.options.width - 150 - 60, 80)

        this.background = new PIXI.Sprite(PIXI.Texture.EMPTY)
        this.background.width = 60
        this.background.height = 60

        this.base = new PIXI.Sprite(PIXI.Texture.from('audio'))
        this.base.position.set(11, 15)

        this.waveSmall = new PIXI.Sprite(PIXI.Texture.from('audio-wave-small'))
        this.waveSmall.position.set(35, 24)

        this.waveMedium = new PIXI.Sprite(PIXI.Texture.from('audio-wave-medium'))
        this.waveMedium.position.set(38, 22)

        this.waveBig = new PIXI.Sprite(PIXI.Texture.from('audio-wave-big'))
        this.waveBig.position.set(40, 19)

        this.muted = new PIXI.Sprite(PIXI.Texture.from('audio-muted'))
        this.muted.position.set(36, 24)
        this.muted.visible = false

        this.addChild(this.background, this.base, this.waveSmall, this.waveMedium, this.waveBig, this.muted)

        this.interactive = true
        this.buttonMode = true

        this.on('pointertap', this.handlePointerTap)

        Audio.on('change', this.handleVolumeChange)
    }

    resize({ width }) {
        this.position.set(width - 150 - 60, 80)
    }

    handlePointerTap = event => {
        event.stopPropagation()
        Audio.toggle()
    }

    handleVolumeChange = ({ level }) => {
        this.waveSmall.visible = level !== Audio.MUTED
        this.waveMedium.visible = level !== Audio.MUTED
        this.waveBig.visible = level !== Audio.MUTED
        this.muted.visible = level === Audio.MUTED

        this.waveSmall.alpha = level > Audio.MUTED ? 1 : 0.4
        this.waveMedium.alpha = level > Audio.LOW ? 1 : 0.4
        this.waveBig.alpha = level > Audio.MEDIUM ? 1 : 0.4
    }

}