import * as PIXI from 'pixi.js'
import sample from 'lodash/sample'
import { randomInteger, weightedRandom } from '../utils/Math'

const pickups = {
    'brick': {
        weight: 0,
        goldWeight: 0,
        value: 50,
        id: 'brick',
        texture: null
    },
    'coin-gold': {
        weight: 30,
        goldWeight: 10,
        value: 10,
        id: 'coin-gold',
        texture: null
    },
    'coin-silver': {
        weight: 50,
        goldWeight: 10,
        value: 5,
        id: 'coin-silver',
        texture: null
    },
    'coin-bronze': {
        weight: 200,
        goldWeight: 0,
        value: 1,
        id: 'coin-bronze',
        texture: null
    }
}

const pickupList = Object.keys(pickups).map(key => pickups[key])

const spark = document.createElement('canvas')
spark.width = 50
spark.height = 100

const context = spark.getContext('2d')
const fill = context.createLinearGradient(0, 0, 50, 0)
fill.addColorStop(0, 'rgba(255, 255, 255, 0)')
fill.addColorStop(0.3, 'rgba(255, 255, 255, 0.4)')
fill.addColorStop(0.5, 'rgba(255, 255, 255, 0.6)')
fill.addColorStop(0.7, 'rgba(255, 255, 255, 0.4)')
fill.addColorStop(1, 'rgba(255, 255, 255, 0)')
context.fillStyle = fill
context.fillRect(0, 0, 50, 100)

const _defaults = {
    bonusRound: false
}

export default class Pickup extends PIXI.Container {
    constructor(options = {}) {
        super()

        this.options = {
            ..._defaults,
            ...options
        }

        this._type = null

        this.tollerance = 5
        this.sprite = null

        this.sparkDelta = 0
        this.spark = new PIXI.Sprite(PIXI.Texture.from(spark))
        this.spark.width = 50
        this.spark.height = 150
        this.spark.anchor.set(0.5, 0.5)
        this.spark.rotation = 0.785398163
        this.spark.position.set(-34, -34)

        this.coinMask = new PIXI.Graphics()
        this.coinMask.beginFill(0x000000)
        this.coinMask.drawCircle(0, 0, 34)
        this.coinMask.endFill()

        this.brickMask = new PIXI.Graphics()
        this.brickMask.position.x = -52
        this.brickMask.position.y = -57
        this.brickMask.beginFill(0x000000)
        this.brickMask.moveTo(54, 0)
        this.brickMask.lineTo(71, 32)
        this.brickMask.lineTo(106, 38)
        this.brickMask.lineTo(80, 63)
        this.brickMask.lineTo(86, 98)
        this.brickMask.lineTo(55, 81)
        this.brickMask.lineTo(23, 98)
        this.brickMask.lineTo(29, 63)
        this.brickMask.lineTo(3, 37)
        this.brickMask.lineTo(39, 33)
        this.brickMask.moveTo(54, 0)
        this.brickMask.endFill()

        const type = this.getType()
        this.type = type.id

        this.felix = null

        this.isPickedUp = false

        this.startState = null

        this.addChild(this.sprite)
        this.addChild(this.spark)
        this.addChild(this.coinMask)
        this.addChild(this.brickMask)
    }

    get box() {
        return {
            x: this.position.x - this.width/2 + this.tollerance,
            y: this.position.y - this.height/2 + this.tollerance,
            width: this.width - this.tollerance * 2,
            height: this.height - this.tollerance * 2
        }
    }

    get type() {
        return this._type
    }

    set type(typeId) {
        if (typeId in pickups && typeId !== this._type) {
            const type = pickups[typeId]

            if (!type.texture) {
                type.texture = PIXI.Texture.from(type.id)
            }

            if (this.sprite) {
                this.sprite.texture = type.texture
            } else {
                this.sprite = new PIXI.Sprite(type.texture)
                this.sprite.anchor.set(0.5, 0.5)
            }

            if (type.id === 'brick') {
                this.brickMask.visible = true
                this.coinMask.visible = false
                this.spark.mask = this.brickMask
            } else {
                this.brickMask.visible = false
                this.coinMask.visible = true
                this.spark.mask = this.coinMask
            }

            this.value = type.value
            this._type = typeId
        }
    }

    update() {
        if (this.felix) {
            if (!this.startState) {
                this.startState = {
                    time: Date.now(),
                    x: this.position.x,
                    y: this.position.y
                }
            }
            const diff = Math.max((this.startState.time + 300 - Date.now()) / 300, 0)
            this.scale.set(diff)
            // this.position.x = this.startState.x - (this.felix.position.x - this.startState.x) * diff
            // this.position.y = this.startState.y - (this.felix.position.y - this.startState.y) * diff
        }

        if (this.sparkDelta > 1 * 60) {
            this.sparkDelta = 0
        }

        const offset = -44 + 2 * this.sparkDelta++
        this.spark.position.set(offset, offset)
    }

    reset(options = {}) {
        this.options.bonusRound = 'bonusRound' in options && options.bonusRound
        const type = this.getType()
        this.sprite.texture = type.texture
        this.type = type.id
        this.value = type.value
        this.felix = null
        this.startState = null
        this.isPickedUp = false
        this.scale.set(1)
    }

    getType() {
        return weightedRandom(pickupList, this.options.bonusRound ? 'goldWeight' : 'weight')
    }
}