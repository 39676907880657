import numbro from 'numbro'
import cultureCZ from 'numbro/languages/cs-CZ'
numbro.registerLanguage(cultureCZ)
numbro.setLanguage('cs-CZ')

export function limit(value, min, max){
    return Math.min(max, Math.max(min, value));
}

export function format(value) {
    return numbro(value).format({
        thousandSeparated: true
    })
}

export function parse(value) {
    return parseFloat(value.replace(/\s/ig, '').replace(',', '.'))
}

export function parseInteger(value) {
    return parseInt(value.replace(/\s/ig, ''))
}

export function formatCurrency(value) {
    return numbro(value).formatCurrency({
        mantissa: 0,
        thousandSeparated: true,
        spaceSeparated: true
    })
}

export function formatPercentage(value) {
    return numbro(value).format({
        output: 'percent',
        mantissa: 2,
        spaceSeparated: true
    })
}