import Component from '../core/Component';

export default class ModalOpener extends Component {

    prepare() {
        this.element.addEventListener('click', ::this.handleClick);
    }

    handleClick(event) {
        event.preventDefault()

        let id = this.element.getAttribute('href') || this.element.dataset.modalId

        if (!id) {
            return;
        }

        id = id.replace('#', '')

        const modal = Component.getFromElement(id)

        if (modal) {
            modal.open()
        }

    }

}