import * as PIXI from 'pixi.js'
import Ground from './Ground'
import AudioButton from './AudioButton'

const BASE_WIDTH = 1920
const BASE_HEIGHT = 1080

const _defaults = {
    width: BASE_WIDTH,
    height: BASE_HEIGHT
}

export default class MainView extends PIXI.Container {
    constructor(options = {}) {
        super()

        this.options = {
            ..._defaults,
            ...options
        }

        this.resize({
            width: this.options.width,
            height: this.options.height
        })

        this.background = new PIXI.Container()
        this.floors = new PIXI.Container()
        this.objects = new PIXI.Container()
        this.character = new PIXI.Container()
        this.snow = new PIXI.Container()
        this.overlay = new PIXI.Container()
        this.ground = new Ground({
            width: this.options.width
        })
        this.ground.position.set(0, BASE_HEIGHT - this.ground.options.height)

        this.audioButton = new AudioButton({
            width: this.options.width
        })
        this.overlay.addChild(this.audioButton)

        this.floors.addChild(this.ground)

        this.addChild(this.background)
        this.addChild(this.floors)
        this.addChild(this.objects)
        this.addChild(this.character)
        this.addChild(this.snow)
        this.addChild(this.overlay)
    }

    resize({ width, height }) {
        const scale = height / BASE_HEIGHT

        this.options.width = width / scale
        this.options.height = height
        this.scale.set(scale, scale)

        if (this.ground) {
            this.ground.resize({
                width: this.options.width,
                height: BASE_HEIGHT
            })
        }

        if (this.audioButton) {
            this.audioButton.resize({
                width: this.options.width,
                height: BASE_HEIGHT
            })
        }
    }
}