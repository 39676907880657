import Enemy from './Enemy'
import ObjectPool from '../utils/ObjectPool'
import Hist from '../utils/Hist'

export default class EnemyManager {
    constructor(engine) {
        this.engine = engine
        this.pool = new ObjectPool(Enemy)
        this.enemies = []

        this.delta = 0
    }

    update(delta) {
        for (let i = 0; i < this.enemies.length; i++) {
            const enemy = this.enemies[i]
            enemy.update()

            enemy.position.x -= 1 * delta * this.engine.speed

            if (enemy.position.x < -200) {
                this.removeEnemy(i)
                i--
            }
        }

        this.delta += delta

        if (this.delta > 60 * 3 * this.engine.baseSpeed / this.engine.speed) {
            this.addEnemy()
            this.delta = 0
        }
    }

    addEnemy() {
        const enemy = this.pool.getObject()
        enemy.position.set(this.engine.view.options.width + 200 + enemy.offset.x, 908 + enemy.offset.y)
        this.enemies.push(enemy)
        Hist.push({
            type: 'enemy:add',
            data: {
                x: enemy.position.x,
                y: enemy.position.y,
                type: enemy.type,
                gameDelta: this.engine.gameDelta
            }
        })
        this.engine.view.objects.addChild(enemy)
    }

    removeEnemy(index) {
        const enemy = this.enemies[index]
        this.engine.view.objects.removeChild(enemy)
        this.enemies.splice(index, 1)
        this.pool.returnObject(enemy)
    }

    collideWithEnemey(index) {
        Hist.push({
            type: 'enemy:collide',
            data: {
                type: this.enemies[index].type
            }
        })
        this.enemies[index].hasCollided = true
    }

    destroyAll() {
        this.enemies.forEach(enemy => this.engine.view.objects.removeChild(enemy))
        this.enemies = []
        this.delta = 0
    }
}