import AES from 'crypto-js/aes'
import CryptoJS from 'crypto-js'
let _events = []

const CryptoJSAesJson = {
    stringify: function (cipherParams) {
        var j = {ct: cipherParams.ciphertext.toString(CryptoJS.enc.Base64)};
        if (cipherParams.iv) j.iv = cipherParams.iv.toString();
        if (cipherParams.salt) j.s = cipherParams.salt.toString();
        return JSON.stringify(j).replace(/\s/g, '');
    },
    parse: function (jsonStr) {
        var j = JSON.parse(jsonStr);
        var cipherParams = CryptoJS.lib.CipherParams.create({ciphertext: CryptoJS.enc.Base64.parse(j.ct)});
        if (j.iv) cipherParams.iv = CryptoJS.enc.Hex.parse(j.iv);
        if (j.s) cipherParams.salt = CryptoJS.enc.Hex.parse(j.s);
        return cipherParams;
    }
}

export function push(event) {
    event = {
        time: Date.now(),
        ...event
    }
    _events.push(event)
}

export function commit() {
    const log = JSON.stringify(_events)
    const stuff = AES.encrypt(log, 'felix', {format: CryptoJSAesJson})
    // console.log(stuff)
    purge()
    return stuff.toString()
}

// function decrypt(data) {
//     const stuff = AES.decrypt(data.check, 'felix', {format: CryptoJSAesJson})
//     console.log(stuff.toString(CryptoJS.enc.Utf8))
// }

// window.decrypt = decrypt

export function purge() {
    _events = []
}

export default {
    push,
    commit,
    purge
}