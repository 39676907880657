const pickupPatterns = {
    SINGLE: 1,
    DOUBLE: 2,
    TRIPPLE: 3,
    QUADRUPLE: 4,
    QUINTUPLE: 5,
    UP: 5,
    DOWN: 6,
    HILL: 7
}

export default pickupPatterns