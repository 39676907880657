import Component from '../core/Component'
import EventBus from '../core/EventBus'
import enquire from 'enquire.js'
import { queries } from '../core/config'

export default class LayerOrientation extends Component {

    constructor(element) {
        super(element)

        this.isRunning = false
        this.isPortrait = false

        this.enquireHandler = {
            match: ::this.match,
            unmatch: ::this.unmatch
        }

        this.enquireQuery = `${queries.mediumMax} and ${queries.portrait}`
    }

    prepare() {
        EventBus.on('game:start', this.handleGameStart)
        EventBus.on('game:stop', this.handleGameStop)
        enquire.register(this.enquireQuery, this.enquireHandler)
    }

    destroy() {
        EventBus.off('game:start', this.handleGameStart)
        EventBus.off('game:stop', this.handleGameStop)
        enquire.unregister(this.enquireQuery, this.enquireHandler)
    }

    handleGameStart = () => {
        this.isRunning = true
        this.check()
    }

    handleGameStop = () => {
        this.isRunning = false
        this.check()
    }

    match() {
        this.isPortrait = true
        this.check()
    }

    unmatch() {
        this.isPortrait = false
        this.check()
    }

    check() {
        if (!this.isRunning) {
            return
        }

        if (this.isPortrait) {
            EventBus.emit('game:pause')
            this.show()
        } else {
            EventBus.emit('game:resume')
            this.hide()
        }
    }

    show() {
        const layer = Component.getFromElement(this.element.closest('[data-component="Layer"]'))

        if (layer) {
            layer.open()
        }
    }

    hide() {
        const layer = Component.getFromElement(this.element.closest('[data-component="Layer"]'))

        if (layer) {
            layer.close()
        }
    }

}