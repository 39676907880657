import * as PIXI from 'pixi.js'
import { TimelineLite, Expo } from 'gsap'
import STAGE from '../constants/stage'
import Audio from './Audio'

export default class CountDown extends PIXI.Container {

    constructor(engine) {
        super()

        this.engine = engine

        this.timeline = new TimelineLite()
        this.hasFinished = false

        this.style = new PIXI.TextStyle({
            dropShadow: true,
            dropShadowAlpha: 0.15,
            dropShadowAngle: 0.785398163,
            dropShadowBlur: 20,
            dropShadowColor: "#000514",
            dropShadowDistance: 4,
            fill: "white",
            fontFamily: ['DINRounded-webfont', 'Arial', 'Helvetica'],
            fontSize: 320,
            letterSpacing: 0,
            lineHeight: 1,
            fontWeight: 500
        })

        this.textReady = new PIXI.Text('Připravit', this.style)
        this.textReady.anchor.set(0.5, 0.5)
        this.textGo = new PIXI.Text('Běž!', this.style)
        this.textGo.anchor.set(0.5, 0.5)
        this.reset()
        this.resize()

        this.timeline
            .add('textReadyStart', '+=0.5')
            .add(() => Audio.play('count1'), 'textReadyStart')
            .to(this.textReady.scale, 1, {
                x: 1,
                y: 1,
                ease: Expo
            }, 'textReadyStart')
            .to(this.textReady, 1, {
                alpha: 1,
                ease: Expo
            }, 'textReadyStart')

            .add('textGoStart')
            .add(() => Audio.play('count2'), 'textGoStart')
            .to(this.textReady.scale, 0.5, {
                x: 1.5,
                y: 1.5,
                ease: Expo
            }, 'textGoStart')
            .to(this.textReady, 0.5, {
                alpha: 0,
                ease: Expo
            }, 'textGoStart')

            .to(this.textGo.scale, 1, {
                x: 1,
                y: 1,
                ease: Expo
            }, 'textGoStart')
            .to(this.textGo, 1, {
                alpha: 1,
                ease: Expo
            }, 'textGoStart')
            .add(() => {
                this.engine.playingStart()
                this.hasFinished = true
            }, 'textGoStart')

            .add('textGoEnd')
            .to(this.textGo.scale, 0.5, {
                x: 1.5,
                y: 1.5,
                ease: Expo
            }, 'textGoEnd')
            .to(this.textGo, 0.5, {
                alpha: 0,
                ease: Expo
            }, 'textGoEnd')
            .add(() => {
                this.visible = false
            })

        this.addChild(this.textReady)
        this.addChild(this.textGo)
    }

    start() {
        this.timeline.play(0)
    }

    stop() {
        this.timeline.stop()
    }

    update() {
    }

    reset() {
        this.textReady.scale.x = .3
        this.textReady.scale.y = .3
        this.textReady.alpha = 0
        this.textGo.scale.x = .3
        this.textGo.scale.y = .3
        this.textGo.alpha = 0
        this.visible = true
        this.hasFinished = false
    }

    resize() {
        const x = this.engine.app.view.width / (this.engine.app.view.height / STAGE.CANVAS_HEIGHT) / 2
        const y = this.engine.app.view.height / 2
        this.textReady.position.set(x, y)
        this.textGo.position.set(x, y)
    }
}