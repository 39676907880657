import Component from '../core/Component'
import EventBus from '../core/EventBus'
import { format } from '../utils/Number'
import $ from 'jquery'
import Nette from '../nette/NetteForms'
import { queryAll } from '../utils/dom'

export default class ScoreForm extends Component {

    constructor(element) {
        super(element)

        this.ref = {
            score: null,
            input: null,
            form: null,
            formItems: []
        }
    }

    prepare() {
        EventBus.on('game:levelup', this.handleLevelUp)
        EventBus.on('game:start', this.handleGameStart)
        EventBus.on('game:pause', this.handleGamePause)
        EventBus.on('game:resume', this.handleGameResume)
        EventBus.on('game:stop', this.handleGameStop)
        EventBus.on('game:reset', this.handleGameReset)
        EventBus.on('game:over', this.handleGameOver)
    }

    handleLevelUp = data => {
        this.stats('levelUp', data)
    }

    handleGameStart = data => {
        this.reset()
        this.stats('start', data)
    }

    handleGamePause = data => {
        this.stats('pause', data)
    }

    handleGameResume = data => {
        this.stats('resume', data)
    }

    handleGameStop = data => {
        this.stats('stop', data)
    }

    handleGameReset = data => {
        this.stats('reset', data)
    }

    handleGameOver = data => {
        this.stats('done', data)

        this.ref.score.innerText = format(data.total)
        this.ref.input.value = data.total
    }

    stats(event, data) {
        $.ajax({
            url: this.ref.form.dataset.ajaxUrl,
            method: 'POST',
            data: {
                event,
                data,
                time: Date.now()
            }
        })
    }

    reset() {
        this.ref.formItems.forEach(input => {
            if (input.type === 'checkbox') {
                input.checked = false
            } else {
                input.value = ''
            }
        })

        this.ref.score.innerText = format(0)
        this.ref.input.value = 0
    }
}