export default class CollisionManager {

    constructor(engine) {
        this.engine = engine
    }

    update() {
        this.felixBox = this.engine.felix.box

        this.playerVsPickup()
        this.playerVsEnemy()
        this.playerVsFloor()
    }

    playerVsPickup() {
        const pickups = this.engine.pickupManager.pickups
        const felix = this.engine.felix
        const felixBox = felix.box

        for (let i = 0; i < pickups.length; i++) {
            var pickup = pickups[i]
            if (pickup.isPickedUp) {
                continue
            }

            // only pick objects "close enough"
            if (Math.abs(felix.position.x - pickup.position.x) < 200 || Math.abs(felix.position.y - pickup.position.y) < 200) {
                if (this.collide(felixBox, pickup.box)) {
                    this.engine.pick({
                        type: pickup.type,
                        value: pickup.value
                    })
                    this.engine.pickupManager.pickPickup(i)
                }
            }
        }
    }

    playerVsEnemy() {
        const enemies = this.engine.enemyManager.enemies
        const felix = this.engine.felix
        const felixBox = felix.box

        for (let i = 0; i < enemies.length; i++) {
            var enemy = enemies[i]

            if (enemy.hasCollided) {
                continue
            }

            if (Math.abs(felix.position.x - enemy.position.x) < 200 || Math.abs(felix.position.y - enemy.position.y) < 200) {
                if (this.collide(felixBox, enemy.box)) {
                    this.engine.enemyManager.collideWithEnemey(i)
                    this.engine.enemy(enemy.action)
                }
            }
        }
    }

    playerVsFloor() {
        const floors = this.engine.platformManager.platforms
        const felix = this.engine.felix
        const felixBox = felix.box

        let groundCollision = false

        for (let i = 0; i < floors.length; i++) {
            var floor = floors[i]
            var floorBox = floor.box

            // horizontal overlay
            if (!this.collideHorizontally(felixBox, floorBox)) {
                continue
            }

            // vertical collision bellow platform
            // if (felix.position.y > floor.position.y && this.collideVerticaly(felixBox, floorBox)) {
            //     felix.headCollision()
            //     continue
            // }

            groundCollision = true

            // felix is above platform, set as ground
            if (felixBox.y + felixBox.height < floorBox.y && felix.platform !== floor) {
                felix.platform = floor
            }
        }

        // reset ground if no collision detected
        if (!groundCollision && felix.platform) {
            felix.platform = null
        }
    }

    collide(rect1, rect2) {
        return (
            rect1.x < rect2.x + rect2.width &&
            rect1.x + rect1.width > rect2.x &&
            rect1.y < rect2.y + rect2.height &&
            rect1.y + rect1.height > rect2.y
        )
    }

    collideHorizontally(rect1, rect2) {
        return (
            rect1.x < rect2.x + rect2.width &&
            rect1.x + rect1.width > rect2.x
        )
    }

    collideVerticaly(rect1, rect2) {
        return (
            rect1.y < rect2.y + rect2.height &&
            rect1.y + rect1.height > rect2.y
        )
    }

}