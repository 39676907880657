import Component from '../core/Component'
import support from '../utils/BrowserSupport'
import keycode from 'keycode'
import { loadComponents, removeComponents } from '../core/componentUtils'
import OverlayService from '../services/Overlay'

export const STATUS = {
    ACTIVE: 'is-active',
    VISIBLE: 'is-visible',
    UPDATING: 'is-updating'
}

export const defaults = {
    close: true
}

export default class Modal extends Component {

    constructor(element) {
        super(element)

        this.ref = {
            background: null,
            layer: null,
            content: null,
            inner: null,
            text: null,
            closeButton: null,
        }

        this.isOpen = false

        this.handleKeyDown = ::this.handleKeyDown

        let options = {}

        if (this.element.dataset.options) {
            options = JSON.parse(this.element.dataset.options)
        }

        this.options = {
            ...defaults,
            ...options
        }
    }

    prepare() {
        if (this.ref.closeButton) {
            this.ref.closeButton.addEventListener('click', ::this.handleCloseClick)
        }

        this.ref.layer.addEventListener(support.transitionEnd, () => {
            if (!this.is(STATUS.ACTIVE)) {
                this.element.classList.remove(STATUS.VISIBLE)
            }
        })
        this.ref.layer.addEventListener('click', ::this.handleClick)
    }

    destroy() {
        document.removeEventListener('keydown', this.handleKeyDown)
        ModalManager.pull(this)
    }

    handleCloseClick(event) {
        event.preventDefault()
        this.close()
    }

    handleClick(event) {
        if (!this.ref.content.contains(event.target)) {
            event.preventDefault()
            this.close()
        }
    }

    handleKeyDown(event) {
        if (keycode(event.keyCode) === 'esc') {
            this.close()
        }
    }

    open() {
        OverlayService.push(this)
        this.element.classList.add(STATUS.VISIBLE)
        this.element.offsetWidth
        this.element.classList.add(STATUS.ACTIVE)
        this.emit('open')

        if (this.ref.inner) {
            this.ref.inner.scrollTop = 0
        }

        if (this.options.close && !this.isOpen) {
            document.addEventListener('keydown', this.handleKeyDown)
        }

        if (this.element.dataset.gtmEvent && dataLayer) {
            const data = JSON.parse(this.element.dataset.gtmEvent)
            dataLayer.push(data)
        }

        this.isOpen = true
    }

    close() {
        OverlayService.pull(this)
        this.element.classList.remove(STATUS.ACTIVE)
        document.removeEventListener('keydown', this.handleKeyDown)
        this.emit('close')

        this.isOpen = false
    }

    setContent(content) {
        if (this.ref.text) {
            this.element.classList.add(STATUS.UPDATING)
            setTimeout(() => {
                removeComponents(this.ref.text)
                this.ref.text.innerHTML = content
                loadComponents(this.ref.text)
                this.element.classList.remove(STATUS.UPDATING)
            }, 200)
        }
    }
}