import Component from '../core/Component'
import EventBus from '../core/EventBus'
import OverlayService from '../services/Overlay'

export default class GameOpener extends Component {

    prepare() {
        this.element.addEventListener('click', this.handleClick)
    }

    handleClick = event => {
        event.preventDefault()
        event.target.blur()

        let showManual = 'false'

        try {
            showManual = sessionStorage.getItem('showManual')
        } catch (e) {}

        if (showManual === 'true') {
            EventBus.emit('manual:show')
        } else {
            const helpLayer = Component.getFromElement('layer-help')
            if (helpLayer) {
                helpLayer.close()
            }
            EventBus.emit('game:show')
        }

    }
}