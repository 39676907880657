import Component from '../core/Component'
import support from '../utils/BrowserSupport'

export const STATUS = {
    ACTIVE: 'is-active',
    VISIBLE: 'is-visible'
}

export const defaults = {
    close: true
}

export default class Layer extends Component {

    prepare() {
        this.element.addEventListener(support.transitionEnd, () => {
            if (!this.is(STATUS.ACTIVE)) {
                this.element.classList.remove(STATUS.VISIBLE)
            }
        })
    }

    toggle() {
        if (this.is(STATUS.ACTIVE)) {
            this.close()
        } else {
            this.open()
        }
    }

    open() {
        this.element.classList.add(STATUS.VISIBLE)
        this.element.offsetWidth
        this.element.classList.add(STATUS.ACTIVE)
    }

    close() {
        this.element.classList.remove(STATUS.ACTIVE)
    }
}