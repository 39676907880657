const colors = {
    rstsYellow: {
        from: '#FFCB2F',
        to: '#FFE466',
        hex: 0xFFCB2F
    },
    yellow: {
        from: '#FBB83F',
        to: '#FDD981',
        hex: 0xFBB83F
    },
    orange: {
        from: '#FF673D',
        to: '#FFA87E',
        hex: 0xFF673D
    },
    green: {
        from: '#49DF6B',
        to: '#91EEAB',
        hex: 0x49DF6B
    },
    blue: {
        from: '#55BCFF',
        to: '#9DDBFF',
        hex: 0x55BCFF
    },
    red:  {
        from: '#FF535F',
        to: '#FF9BA2',
        hex: 0xFF535F
    },
    purple: {
        from: '#A44BFF',
        to: '#CE94FF',
        hex: 0xA44BFF
    },
    gray: {
        from: '#778B94',
        to: '#B2BFC4',
        hex: 0x778B94
    },
    brown: {
        from: '#9C837B',
        to: '#C9BAB5',
        hex: 0x9C837B
    },
    lightBlue: {
        from: '#87D6FF',
        to: '#BCEAFF',
        hex: 0x87D6FF
    }
}

export default colors