import * as PIXI from 'pixi.js'

export default class Score extends PIXI.Container {
    constructor(engine) {
        super()
        this.engine = engine
        this.score = 0

        this.position.x = 70
        this.position.y = 70

        const coin = new PIXI.Sprite(PIXI.Texture.from('coin-score'))

        this.style = new PIXI.TextStyle({
            dropShadow: true,
            dropShadowAlpha: 0.15,
            dropShadowAngle: 0.785398163,
            dropShadowBlur: 2,
            dropShadowColor: "#000514",
            dropShadowDistance: 3,
            fill: "white",
            fontFamily: ['DINRounded-webfont', 'Arial', 'Helvetica'],
            fontSize: 70,
            letterSpacing: 0,
            lineHeight: 1,
            fontWeight: 500
        })

        this.text = new PIXI.Text('0', this.style)
        this.text.position.x = 90
        this.text.position.y = -3

        this.addChild(this.text)
        this.addChild(coin)
    }

    update(score) {
        if (score !== this.score) {
            this.score = score
            this.text.text = this.score
        }
    }
}