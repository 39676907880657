import * as PIXI from 'pixi.js'
import 'pixi-spine'
import keycode from 'keycode'
import Emitter from './utils/Emitter'
import config from './constants/config'
import Engine from './objects/Engine'
import Audio from './objects/Audio'
import throttle from 'lodash/throttle'
import enquire from 'enquire.js'
import Hist from './utils/Hist'

export default class Game extends Emitter {

    constructor(container) {
        super()

        this.container = container
        this.isLoaded = false
        this.isReady = false
        this.isPlaying = false
        this.isPaused = false
        this.shouldStart = false
        this.isDesktop = false

        this.app = null
        this.engine = null

        PIXI.loader
            .add('felix', `${config.assetFolder}/img/game-sprites/felix.json`)
            .add('objects', `${config.assetFolder}/img/game-sprites/objects.json`)
            .add('objects2', `${config.assetFolder}/img/game-sprites/objects2.json`)
            .add('winter', `${config.assetFolder}/img/game-sprites/winter.json`)
            .add('winter-houses', `${config.assetFolder}/img/game-sprites/winter-houses.json`)
            .add('housesTrees', `${config.assetFolder}/img/game-sprites/houses-trees.json`)
            .add('patterns', `${config.assetFolder}/img/game-sprites/patterns.json`)
            .add('pickups', `${config.assetFolder}/img/game-sprites/pickups.json`)
            .add('platform', `${config.assetFolder}/img/game-sprites/platform.json`)
            // .add('countryside', `${config.assetFolder}/img/game-sprites/countryside.json`)
            .add('felixSpine', `${config.assetFolder}/img/felix/felix.json`)
            .load(this.handleAssetsLoaded)

        this.handleResize = throttle(::this.handleResize, 200)

        window.addEventListener('resize', this.handleResize)
        enquire.register('screen and (min-width: 1200px)', {
            match: () => this.isDesktop = true,
            unmatch: () => this.isDesktop = false
        })
    }

    create() {
        this.app = new PIXI.Application({
            autoResize: true,
            // width: this.container.offsetWidth,
            // height: this.container.offsetHeight,
            backgroundColor: 0xE6EEF5,
            autoStart: false,
            resolution: this.isDesktop ? 1 : Math.min(devicePixelRatio, 2)
        })

        this.container.appendChild(this.app.view)

        this.app.stop()
        this.app.renderer.resize(this.container.clientWidth, this.container.clientHeight)
        this.app.leaderboardUrl = this.container.dataset.leaderboardUrl

        if (this.app.renderer.type === PIXI.RENDERER_TYPE.WEBGL) {
            this.app.renderer.plugins.sprite.sprites.length = 0
        }

        this.engine = new Engine(this.app)
        this.engine.on('game:over', data => this.emit('game:over', data))
        this.engine.on('game:playing:start', () => this.isPlaying = true)
        this.engine.on('game:playing:stop', () => this.isPlaying = false)
        this.engine.on('game:levelup', data => this.emit('game:levelup', data))
        this.app.stage.addChild(this.engine.view)
        this.app.stage.interactive = true
        this.app.stage.hitArea = this.app.screen
        this.app.stage.on('pointerdown', event => {
            if (!event?.data?.originalEvent?.isTrusted) {
                return
            }

            if (this.isPlaying) {
                this.engine.felix.jump('pointerdown')
            }
        })

        this.app.ticker.add(delta => this.engine.update(delta))

        this.isReady = true
    }

    attach() {
        document.addEventListener('keydown', this.handleKeyDown)
    }

    detach() {
        document.removeEventListener('keydown', this.handleKeyDown)
    }

    start(resume = false) {
        if (!this.isLoaded) {
            this.shouldStart = true
            return
        }

        if (!this.isReady) {
            this.create()
        }

        this.attach()

        this.app.start()

        if (resume) {
            this.resize()
            if (!this.engine.countdown.hasFinished) {
                this.engine.countdown.start()
            }
            Hist.push({
                type: 'game:resume'
            })
            this.emit('game:resume')
        } else {
            this.engine.countdown.start()
            Hist.push({
                type: 'game:start'
            })
            this.emit('game:start')
        }

        return this
    }

    stop(pause = false) {
        Audio.stop('music')
        this.detach()
        this.app.stop()

        if (pause) {
            Hist.push({
                type: 'game:pause'
            })
            this.emit('game:pause')
            this.engine.countdown.stop()
        } else {
            this.isPlaying = false
            Hist.push({
                type: 'game:stop'
            })
            this.emit('game:stop')
        }

        return this
    }

    pause() {
        Audio.stop('music')
        this.isPaused = true
        this.stop(true)
    }

    resume() {
        this.isPaused = false
        this.start(true)
    }

    reset() {
        this.isPlaying = false
        this.isPaused = false
        if (this.engine) {
            this.engine.reset()
        }
        return this
    }

    show() {
        this.container.classList.add('is-active')
        return this
    }

    hide() {
        this.container.classList.remove('is-active')
        return this
    }

    resize() {
        if (!this.app) {
            return
        }

        this.app.renderer.resize(this.container.clientWidth, this.container.clientHeight)
        this.engine.resize()

        if (this.isPaused) {
            this.engine.update(0)
        }
    }

    handleResize() {
        this.resize()
    }

    handleKeyDown = event => {
        if (!event.isTrusted) {
            return
        }
        const key = keycode(event.keyCode)
        if (key === 'space' || key === 'up' || key === 'w') {
            this.engine.felix.jump(`key:${key}`)
        }

        if (key === 'm') {
            Audio.toggle()
        }
    }

    handleAssetsLoaded = () => {
        this.isLoaded = true

        if (this.shouldStart) {
            this.start()
        }
    }

}