import scrollToElement from '../../services/Viewport/scrollToElement';

document.addEventListener('nette:valid', event => {
    if (event.defaultPrevented) {
        return
    }

    const element = event.target;

    const parent = element.dataset.errorParent ? element.closest(element.dataset.errorParent) : element.parentNode

    if (element.tagName.toLowerCase() != 'button') {
        const errorElement = parent.querySelector('.InputError');
        if (errorElement) {
            parent.removeChild(errorElement);
        }
    }

    element.classList.remove('has-error');
    element.classList.add('is-valid');
});

document.addEventListener('nette:invalid', event => {
    if (event.defaultPrevented) {
        return
    }

    const element = event.target;
    const parent = element.dataset.errorParent ? element.closest(element.dataset.errorParent) : element.parentNode

    let errorElement = parent.querySelector('.InputError');

    if (!errorElement) {
        errorElement = document.createElement('div');
        errorElement.className = 'InputError';
    }

    errorElement.innerHTML = element.getAttribute('data-nette-error');
    parent.appendChild(errorElement);
    element.classList.add('has-error');
    element.classList.remove('is-valid');
});

document.addEventListener('nette:form:valid', event => console.info('Form valid', event.target));
document.addEventListener('nette:form:invalid', event => {
    const form = event.target
    const error = form.querySelector('.InputError')
    const target = error ? error.closest('.FormItem') : null
    scrollToElement(target ? target : form, -120);
});