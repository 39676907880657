import Component from '../core/Component'
import support from '../utils/BrowserSupport'
import keycode from 'keycode'
import OverlayService from '../services/Overlay'

export const STATUS = {
    ACTIVE: 'is-active',
    VISIBLE: 'is-visible'
}

export const defaults = {
    close: true
}

export default class Nav extends Component {

    constructor(element) {
        super(element)

        this.ref = {
            content: null
        }

        this.isOpen = false

        let options = {}

        if (this.element.dataset.options) {
            options = JSON.parse(this.element.dataset.options)
        }

        this.options = {
            ...defaults,
            ...options
        }
    }

    prepare() {
        this.ref.content.addEventListener(support.transitionEnd, () => {
            if (!this.is(STATUS.ACTIVE)) {
                this.element.classList.remove(STATUS.VISIBLE)
            }
        })
        this.element.addEventListener('click', this.handleClick)
    }

    destroy() {
        document.removeEventListener('keydown', this.handleKeyDown)
    }

    handleClick = event => {
        if (!this.ref.content.contains(event.target)) {
            event.preventDefault()
            this.close()
        }

        if (event.target.closest('a')) {
            this.close()
        }
    }

    handleKeyDown = event => {
        if (keycode(event.keyCode) === 'esc') {
            this.close()
        }
    }

    toggle() {
        if (this.is(STATUS.ACTIVE)) {
            this.close()
        } else {
            this.open()
        }
    }

    open() {
        OverlayService.push(this)
        this.element.classList.add(STATUS.VISIBLE)
        this.element.offsetWidth
        this.element.classList.add(STATUS.ACTIVE)
        this.emit('open')
        document.addEventListener('keydown', this.handleKeyDown)
    }

    close() {
        OverlayService.pull(this)
        this.element.classList.remove(STATUS.ACTIVE)
        document.removeEventListener('keydown', this.handleKeyDown)
        this.emit('close')
    }
}