import * as PIXI from 'pixi.js'
import Wallpaper from './Wallpaper'
import ENTRANCE from '../constants/entranceTypes'

const defaults = {
    width: 1920
}

function createSkyTexture(from, to) {
    const canvas = document.createElement('canvas')
    canvas.width = 128
    canvas.height = 128
    const context = canvas.getContext('2d')
    const fill = context.createLinearGradient(0, 0, 0, 128)
    fill.addColorStop(0, from)
    fill.addColorStop(1, to)
    context.fillStyle = fill
    context.fillRect(0, 0, 128, 128)

    return canvas
}

export default class Scene extends PIXI.Container {
    constructor(defs, options) {
        super()

        this.defs = {...defs}

        this._hasEntrance = false

        this.options = {
            ...defaults,
            ...options
        }

        const { items = [], floor, wallpaper, layers, sky } = this.defs

        // objects
        this.items = items
            .map(item => ({
                sprite: PIXI.Sprite.from(PIXI.Texture.from(item.name)),
                defs: { ...item }
            }))
            .map(item => {
                item.defs.left = Math.round(item.sprite.width / 2 + item.defs.left)
                item.sprite.anchor.set(0.5, item.defs.top != null ? 0 : 1)
                return item
            })

        this.items.forEach(item => {
            const { left, bottom, top } = item.defs
            const x = Math.round(left * this.options.width / this.defs.baseWidth)
            const y = top != null ? top : this.options.height - bottom
            item.sprite.position.set(x, y)
        })

        // sky FIXME: add support for gradient in Wallpaper
        this.sky = null
        if (sky) {
            this.sky = new PIXI.Sprite(PIXI.Texture.from(createSkyTexture(sky.from, sky.to)))
            this.sky.width = this.options.width
            this.sky.height = sky.height || 580
        }

        // layers
        this.layers = null
        this.textures = null
        if (layers) {
            this.textures = layers.reduce((acc, layer) => {
                acc[layer.name] = PIXI.Texture.from(layer.name)
                return acc
            }, {})

            // Object.fromEntries(layers.map(layer => [layer.name, PIXI.Texture.from(layer.name)]))
            this.layers = layers
                .map((layer, index) => {
                    const { top, bottom } = layer
                    const y = top != null ? top : this.options.height - bottom - this.textures[layer.name].height

                    const sprite = PIXI.extras.TilingSprite.from(this.textures[layer.name])
                    sprite.width = this.options.width
                    sprite.height = this.textures[layer.name].height
                    sprite.position.set(0, y)

                    return {
                        sprite,
                        name: layer.name,
                        parallax: layer.parallax || 0
                    }
                })
        }

        this.floor = null
        if (floor) {
            this.floor = new PIXI.extras.TilingSprite(PIXI.Texture.from('floor'), this.options.width, floor.height)
            this.floor.anchor.set(0, 1)
            this.floor.position.set(0, this.options.height)
        }

        this.wallpaper = null
        if (wallpaper) {
            this.wallpaper = new Wallpaper({
                defs: wallpaper,
                width: this.options.width,
                height: this.options.height - floor.height
            })
            this.wallpaper.position.set(0, 0)

            const tint = this.wallpaper.getHexColor()

            this.items.forEach(item => {
                if (item.defs.tint) {
                    item.sprite.tint = tint
                }
            })
        }

        this.entrance = new PIXI.Sprite(PIXI.Texture.from('doors-wooden'))
        this.entrance.anchor.set(0, 1)
        this.entrance.position.set(0, this.options.height)
        this.entrance.visible = false

        this.modernEntrance = new PIXI.Sprite(PIXI.Texture.from('doors-glass'))
        this.modernEntrance.anchor.set(0, 1)
        this.modernEntrance.position.set(0, this.options.height)
        this.modernEntrance.visible = false

        this.wallpaper && this.addChild(this.wallpaper)
        this.sky && this.addChild(this.sky)
        this.layers && this.layers.forEach(layer => this.addChild(layer.sprite))
        this.floor && this.addChild(this.floor)
        this.items.length && this.addChild(...this.items.map(item => item.sprite))
        this.addChild(this.entrance)
        this.addChild(this.modernEntrance)
    }

    get hasEntrance() {
        return this._hasEntrance
    }

    set hasEntrance(type = ENTRANCE.NONE) {
        this._hasEntrance = type
        this.entrance.visible = type === ENTRANCE.WOODEN
        this.modernEntrance.visible = type === ENTRANCE.MODERN
    }

    update({ speed, delta }) {
        if (this.layers) {
            this.layers.forEach((layer, index) => layer.parallax && (layer.sprite.tilePosition.x += speed * delta / layer.parallax))
        }
    }

    reset() {
        if (this.wallpaper) {
            this.wallpaper.shuffle()
            const tint = this.wallpaper.getHexColor()

            this.items.forEach(item => {
                if (item.defs.tint) {
                    item.sprite.tint = tint
                }
            })
        }
    }

    getData() {
        return {
            baseWidth: this.defs.baseWidth,
            wallpaper: this.wallpaper?.getData()
        }
    }
}