import PATTERN from './pattern'
import SCENE from './sceneTypes'

const Scenes = {
    winterCountryside: {
        type: SCENE.OUTDOOR,
        baseWidth: 4000,
        items: [
            {
                name: 'outside-sidewalk-oval-small',
                top: 825,
                left: 150
            },
            {
                name: 'outside-sidewalk-oval-large',
                top: 851,
                left: 671
            },
            {
                name: 'outside-sidewalk-oval-medium',
                top: 850,
                left: 1517
            },
            {
                name: 'outside-sidewalk-oval-small',
                top: 827,
                left: 1620
            },
            {
                name: 'outside-sidewalk-oval-large',
                top: 871,
                left: 2199
            },
            {
                name: 'outside-sidewalk-oval-large',
                top: 829,
                left: 3503
            },
            {
                name: 'outside-sidewalk-oval-small',
                top: 896,
                left: 3818
            },
            {
                name: 'tree-oval-small',
                top: 522,
                left: 150
            },
            {
                name: 'house-blue',
                top: 545,
                left: 250
            },
            {
                name: 'tree-oval-medium',
                top: 590,
                left: 427
            },
            {
                name: 'house-blue-small',
                top: 481,
                left: 937
            },
            {
                name: 'house-red-small',
                top: 507,
                left: 983
            },
            {
                name: 'tree-round-medium',
                top: 656,
                left: 1107
            },
            {
                name: 'tree-oval-small',
                top: 560,
                left: 1484
            },
            {
                name: 'house-orange',
                top: 591,
                left: 1595
            },
            {
                name: 'tree-round-small',
                top: 578,
                left: 1904
            },
            {
                name: 'tree-round-medium',
                top: 641,
                left: 2021
            },
            {
                name: 'house-yellow',
                top: 608,
                left: 2226
            },
            {
                name: 'tree-oval-small',
                top: 635,
                left: 2505
            },
            {
                name: 'house-red',
                top: 497,
                left: 2564
            },
            {
                name: 'tree-round-small',
                top: 545,
                left: 2724
            },
            {
                name: 'house-brown',
                top: 584,
                left: 3029
            },
            {
                name: 'tree-round-medium',
                top: 652,
                left: 3193
            },
            {
                name: 'church',
                top: 501,
                left: 3472
            },
            {
                name: 'tree-round-large',
                top: 486,
                left: 3779
            },
            {
                name: 'house-yellow-small',
                top: 596,
                left: 3923
            },
        ],
        layers: [
            {
                name: 'outside-mountains',
                bottom: 408,
                parallax: 3
            },
            {
                name: 'outside-hills',
                bottom: 349,
                parallax: 4
            },
            {
                name: 'outside-snow',
                bottom: 128
            },
            {
                name: 'outside-sidewalk',
                bottom: 0
            }
        ],
        sky: {
            from: '#BCE0E4',
            to: '#DAEFF1',
            height: 780
        },
        floor: null,
        wallpaper: null
    },
    winterVillage: {
        type: SCENE.OUTDOOR,
        baseWidth: 4000,
        items: [
            {
                name: 'village-group-first',
                bottom: 0,
                left: 37
            },
            {
                name: 'village-group-second',
                bottom: 0,
                left: 474
            },
            {
                name: 'village-group-third',
                bottom: 0,
                left: 919
            },
            {
                name: 'village-group-fourth',
                bottom: 0,
                left: 1324
            },
            {
                name: 'village-group-short',
                bottom: 0,
                left: 1924
            },
            {
                name: 'village-group-third',
                bottom: 0,
                left: 2876
            },
            {
                name: 'village-group-first',
                bottom: 0,
                left: 3314
            }
        ],
        layers: [
            {
                name: 'village-mountains-big',
                bottom: 200,
                parallax: 3
            },
            {
                name: 'village-mountains-small',
                bottom: 173,
                parallax: 5
            },
            {
                name: 'village-snow',
                bottom: 153
            },
            {
                name: 'village-sidewalk',
                bottom: 0
            }
        ],
        sky: {
            from: '#BFE2ED',
            to: '#DDF0F6',
            height: 740
        },
        floor: null,
        wallpaper: null
    },
    winterCity: {
        type: SCENE.OUTDOOR,
        baseWidth: 4000,
        items: [
            {
                name: 'winter-tree-big',
                left: 57,
                bottom: 127
            },
            {
                name: 'city-house-blue',
                left: 251,
                bottom: 127
            },
            {
                name: 'winter-tree-small',
                left: 229,
                bottom: 127
            },
            {
                name: 'winter-tree-small',
                left: 582,
                bottom: 127
            },
            {
                name: 'city-house-yellow',
                left: 747,
                bottom: 127
            },
            {
                name: 'city-house-red',
                left: 817,
                bottom: 127
            },
            {
                name: 'winter-tree-small',
                left: 1368,
                bottom: 127
            },
            {
                name: 'city-house-wooden',
                left: 1436,
                bottom: 127
            },
            {
                name: 'winter-tree-small',
                left: 2032,
                bottom: 127
            },
            {
                name: 'winter-tree-big',
                left: 2308,
                bottom: 127
            },
            {
                name: 'winter-tree-small',
                left: 2693,
                bottom: 127
            },
            {
                name: 'city-house-wooden',
                left: 2772,
                bottom: 127
            },
            {
                name: 'city-house-blue',
                left: 2907,
                bottom: 127
            },
            {
                name: 'winter-tree-big',
                left: 3220,
                bottom: 127
            },
            {
                name: 'city-house-red',
                left: 3466,
                bottom: 127
            },
            {
                name: 'city-house-yellow',
                left: 3840,
                bottom: 127
            },
        ],
        layers: [
            {
                name: 'city-shadow-background',
                bottom: 127,
                parallax: 6
            },
            {
                name: 'city-shadow-foreground',
                bottom: 127,
                parallax: 10
            },
            {
                name: 'city-sidewalk',
                bottom: 0
            }
        ],
        sky: {
            from: '#323F45',
            to: '#687D88'
        },
        floor: null,
        wallpaper: null
    },
    rsts: {
        type: SCENE.INDOOR,
        baseWidth: 3063,
        items: [
            {
                name: 'doors-glass',
                left: 0,
                bottom: 0
            },
            {
                name: 'window-winter-day',
                left: 437,
                bottom: 305
            },
            {
                name: 'window-winter-night',
                left: 2601,
                bottom: 305
            },
            {
                name: 'lamp-green',
                left: 793,
                bottom: 120
            },
            {
                name: 'chair-left-gray',
                left: 551,
                bottom: 108
            },
            {
                name: 'table-small-light',
                left: 1435,
                bottom: 125
            },
            {
                name: 'plant-floor',
                left: 1510,
                bottom: 94
            },
            {
                name: 'painting-rsts',
                left: 2098,
                top: 178
            },
            {
                name: 'light-conic',
                left: 451,
                top: 0
            },
            {
                name: 'light-conic',
                left: 1618,
                top: -92
            },
            {
                name: 'light-conic',
                left: 1670,
                top: -13
            },
            {
                name: 'plant-floor',
                left: 2583,
                bottom: 100
            },
            {
                name: 'table-office-white',
                left: 2034,
                bottom: 112
            }
        ],
        floor: {
            height: 128
        },
        wallpaper: {
            image: null,
            pattern: PATTERN.NONE,
            color: 'rstsYellow'
        }
    },
    kitchenModern: {
        type: SCENE.INDOOR,
        baseWidth: 2268,
        items: [
            {
                name: 'painting-frames',
                left: 151,
                bottom: 93
            },
            {
                name: 'plant-floor',
                left: 312,
                bottom: 63
            },
            {
                name: 'table-dining-modern',
                left: 760,
                bottom: 83
            },
            {
                name: 'light-bulb-small',
                left: 913,
                top: -66
            },
            {
                name: 'light-bulb-small',
                left: 936,
                top: -114
            },
            {
                name: 'light-bulb-small',
                left: 946,
                top: 0
            },
            {
                name: 'light-bulb-small',
                left: 970,
                top: -39
            },
            {
                name: 'kitchen',
                left: 1379,
                bottom: 93
            }
        ],
        floor: {
            height: 128
        },
        wallpaper: {
            image: null,
            pattern: PATTERN.RANDOM,
            color: null
        }
    },
    homeOffice: {
        type: SCENE.INDOOR,
        baseWidth: 2749,
        items: [
            {
                name: 'light-wood',
                left: 369,
                top: 0
            },
            {
                name: 'table-office-brown',
                left: 292,
                bottom: 112
            },
            {
                name: 'bin-brown',
                left: 883,
                bottom: 120
            },
            {
                name: 'table-small-light',
                left: 1601,
                bottom: 120
            },
            {
                name: 'chair-right-gray',
                left: 1849,
                bottom: 108
            },
            {
                name: 'plant-floor',
                left: 2460,
                bottom: 76
            },
            {
                name: 'painting-shapes-rounded',
                left: 1356,
                bottom: 508,
                tint: true
            }
        ],
        floor: {
            height: 128
        },
        wallpaper: {
            image: null,
            pattern: PATTERN.RANDOM,
            color: null
        }
    },
    bedRoom: {
        type: SCENE.INDOOR,
        baseWidth: 2925,
        items: [
            {
                name: 'plant-floor',
                left: 503,
                bottom: 78
            },
            {
                name: 'painting-landscape',
                left: 886,
                bottom: 375,
                tint: true
            },
            {
                name: 'bed-black',
                left: 664,
                bottom: 100
            },
            {
                name: 'sofa-black',
                left: 1828,
                bottom: 100
            },
            {
                name: 'painting-group',
                left: 2039,
                bottom: 508,
                tint: true
            }
        ],
        floor: {
            height: 128
        },
        wallpaper: {
            image: null,
            pattern: PATTERN.RANDOM,
            color: null
        }
    },
    bathRoom: {
        type: SCENE.INDOOR,
        baseWidth: 1826,
        items: [
            {
                name: 'toilet',
                left: 247,
                bottom: 104
            },
            {
                name: 'window',
                left: 216,
                bottom: 392
            },
            {
                name: 'shower',
                left: 440,
                bottom: 107
            },
            {
                name: 'mirror',
                left: 1009,
                bottom: 380
            },
            {
                name: 'drawers-bathroom',
                left: 940,
                bottom: 91
            },
            {
                name: 'light-bulb-medium',
                left: 1298,
                top: 0
            },
            {
                name: 'light-bulb-medium',
                left: 1336,
                top: -40
            }
        ],
        floor: {
            height: 128
        },
        wallpaper: {
            image: null,
            pattern: [PATTERN.BRICKS, PATTERN.NONE],
            color: 'lightBlue'
        }
    },
    livingRoomYellow: {
        type: SCENE.INDOOR,
        baseWidth: 2287,
        items: [
            {
                name: 'cabinet-yellow',
                left: 150,
                bottom: 118
            },
            {
                name: 'lamp-green',
                left: 558,
                bottom: 122
            },
            {
                name: 'table-small-light',
                left: 694,
                bottom: 122
            },
            {
                name: 'sofa-yellow',
                left: 763,
                bottom: 72
            },
            {
                name: 'chair-right-gray',
                left: 1821,
                bottom: 110
            },
            {
                name: 'light-conic',
                top: 0,
                left: 679
            }
        ],
        floor: {
            height: 128
        },
        wallpaper: {
            image: null,
            pattern: PATTERN.RANDOM,
            color: null
        }
    },
    kitchenWooden: {
        type: SCENE.INDOOR,
        baseWidth: 2227,
        items: [
            {
                name: 'cabinet-tv-brown',
                left: 160,
                bottom: 116
            },
            {
                name: 'plant-ceiling',
                left: 628,
                top: 0
            },
            {
                name: 'table-dining',
                left: 721,
                bottom: 83
            },
            {
                name: 'cabinet-large-brown',
                left: 1790,
                bottom: 115
            }
        ],
        floor: {
            height: 128
        },
        wallpaper: {
            image: null,
            pattern: PATTERN.RANDOM,
            color: null
        }
    },
    childRoom: {
        type: SCENE.INDOOR,
        baseWidth: 2854,
        items: [
            {
                name: 'bunny',
                left: 169,
                bottom: 75
            },
            {
                name: 'drawers-white',
                left: 509,
                bottom: 119
            },
            {
                name: 'horse',
                left: 713,
                bottom: 62
            },
            {
                name: 'bed-child',
                left: 1020,
                bottom: 69
            },
            {
                name: 'lamp-white',
                left: 1759,
                bottom: 106
            },
            {
                name: 'chair-right-white',
                left: 1914,
                bottom: 75
            },
            {
                name: 'teepee',
                left: 2269,
                bottom: 108
            }
        ],
        floor: {
            height: 128
        },
        wallpaper: {
            image: null,
            pattern: PATTERN.STARS,
            color: 'brown'
        }
    },
    livingRoomBlue: {
        type: SCENE.INDOOR,
        baseWidth: 4126,
        items: [
            {
                name: 'cabinet-tv-blue',
                left: 636,
                bottom: 120
            },
            {
                name: 'chair-left-blue',
                left: 868,
                bottom: 74
            },
            {
                name: 'sofa-blue',
                left: 1228,
                bottom: 60
            },
            {
                name: 'plant-ceiling',
                left: 1770,
                top: 0
            },
            {
                name: 'chair-right-blue',
                left: 2340,
                bottom: 114
            },
            {
                name: 'table-small-dark',
                left: 3104,
                bottom: 94
            },
            {
                name: 'plant-floor',
                left: 3152,
                bottom: 68
            },
            {
                name: 'light-conic',
                left: 3534,
                top: 0
            }
        ],
        floor: {
            height: 128
        },
        wallpaper: {
            image: null,
            pattern: PATTERN.RANDOM,
            color: null
        }
    }
}

export default Scenes