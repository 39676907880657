import Pickup from './Pickup'
import ObjectPool from '../utils/ObjectPool'
import { randomInteger, randomFloat, weightedRandom } from '../utils/Math'
import pickupPatterns from '../constants/pickupPatterns'
import Hist from '../utils/Hist'

const FPS = 60
const MIN_PICKUP_SPACE = 20

const patterns = [
    {
        value: 1,
        weight: 150,
        type: pickupPatterns.SINGLE
    },
    {
        value: 2,
        weight: 20,
        type: pickupPatterns.DOUBLE
    },
    {
        value: 3,
        weight: 10,
        type: pickupPatterns.TRIPPLE
    },
    {
        value: 4,
        weight: 5,
        type: pickupPatterns.QUADRUPLE
    }
]

const _defaultPreset = (new Array(100)).fill('coin-bronze').concat((new Array(20)).fill('coin-silver')).concat((new Array(10)).fill('coin-gold'))

export default class PickupManager {
    constructor(engine) {
        this.engine = engine
        this.pool = new ObjectPool(Pickup)
        this.pickups = []

        this.delta = 0
        this.spawnDelay = 3 * FPS
        this.bonusDelta = 0
        this.bonusSpawnDelay = 10 * FPS
        this.bonusRoundSpawnDelay = 600 // ms
        this.bonusRoundTimer = null
        this.bonusRoundCounter = 0

        this.preset = Array.from(_defaultPreset)
        this.presetLength = this.preset.length

        this.engine.on('game:bonusround:start', this.handleBonusRoundStart)
    }

    update(delta) {
        for (let i = 0; i < this.pickups.length; i++) {
            const pickup = this.pickups[i]
            pickup.update()

            if (pickup.isPickedUp) {
                if (pickup.scale.x < 0.1) {
                    this.removePickup(i)
                    i--
                }
            } else {
                pickup.position.x -= 1 * delta * this.engine.speed

                if (pickup.position.x < -200) {
                    this.removePickup(i)
                    i--
                }
            }
        }

        this.delta += delta
        this.bonusDelta += delta

        if (this.delta > this.spawnDelay) {
            this.delta = 0
            this.addPickup()
        }

        if (this.bonusDelta > this.bonusSpawnDelay) {
            this.bonusDelta = 0
            this.addBonusPickup()
        }
    }

    handleBonusRoundStart = () => {
        this.bonusRoundCounter = 0
        this.bonusRoundTimer = setInterval(() => {
            // skip first
            this.bonusRoundCounter++

            if (this.bonusRoundCounter === 1) {
                return
            }

            if (this.bonusRoundCounter < 12) {
                this.addBonusPickup(true)
                // console.log('bonus round cihla')
            } else {
                this.bonusRoundCounter = 0
                clearInterval(this.bonusRoundTimer)
            }
        }, this.bonusRoundSpawnDelay / this.engine.speedFactor);
        // console.log('bonus round started')
    }

    getPickupOffset() {
        let x = this.engine.view.options.width + 200
        const y = 787 - randomInteger(0, 1) * 465

        if (this.pickups.length) {
            const lastPickup = this.pickups[this.pickups.length - 1]
            const lastX = lastPickup.position.x + lastPickup.width + MIN_PICKUP_SPACE

            if (lastX > x) {
                x = lastX
            }
        }

        return {
            x,
            y
        }
    }

    addBonusPickup(force = false) {
        if (this.preset.length && !force) {
            return
        }

        const pickup = this.pool.getObject()
        pickup.type = 'brick'

        const { x , y } = this.getPickupOffset()

        pickup.position.set(x, y)
        this.pickups.push(pickup)

        Hist.push({
            type: 'pickup:add',
            data: [{
                x,
                y,
                type: pickup.type
            }]
        })

        this.engine.view.objects.addChild(pickup)
    }

    addPickup() {
        const { x, y } = this.getPickupOffset()

        const pattern = weightedRandom(patterns)
        const double = randomInteger(1, 2)
        const count = pattern.value * double

        let pickups = []

        if (this.preset.length && !this.bonusRound) {
            pickups = (new Array(count)).fill(0).map(item => {
                const index = randomInteger(0, this.preset.length - 1)
                const type = this.preset.splice(index, 1)[0]
                const pickup = this.pool.getObject()
                pickup.type = type
                return pickup
            })
        } else {
            pickups = this.createPickups(count)
        }

        this.pickups = this.pickups.concat(pickups)

        pickups.forEach((pickup, index) => {
            pickup.position.set(x + index * (pickup.width + MIN_PICKUP_SPACE) , y)
            this.engine.view.objects.addChild(pickup)
        })

        Hist.push({
            type: 'pickup:add',
            data: pickups.map(pickup => ({
                x: pickup.x,
                y: pickup.y,
                type: pickup.type,
                gameDelta: this.engine.gameDelta
            }))
        })

        this.spawnDelay = randomFloat(0.3, 1.5) * FPS * (this.engine.bonusRound ? 0.4 : 0.7) * (this.engine.baseSpeed / this.engine.speed)
    }

    createPickups(count) {
        return (new Array(count))
            .fill(0)
            .map(item => this.pool.getObject({
                bonusRound: this.engine.bonusRound
            }))
    }

    removePickup(index) {
        const pickup = this.pickups[index]
        this.engine.view.objects.removeChild(pickup)
        this.pickups.splice(index, 1)
        this.pool.returnObject(pickup)
    }

    pickPickup(index) {
        const pickup = this.pickups[index]
        pickup.isPickedUp = true
        pickup.felix = this.engine.felix
        Hist.push({
            type: 'pickup:pick',
            data: {
                type: pickup.type,
                value: pickup.value
            }
        })
    }

    destroyAll() {
        this.pickups.forEach(pickup => this.engine.view.objects.removeChild(pickup))
        this.pickups = []
        this.delta = 0
        this.preset = Array.from(_defaultPreset)
        this.presetLength = this.preset.length
        clearInterval(this.bonusRoundTimer)
    }
}