export function randomInteger(from, to) {
    return Math.floor(Math.random() * (to - from + 1) + from)
}

export function randomFloat(from, to) {
    return Math.random() * (to - from) + from
}

export function weightedRandom(items, key = 'weight') {
    const sum = items.reduce((acc, item) => acc + item[key], 0)

    let current = randomInteger(0, sum - 1)

    return items.find(item => {
        if (item[key] > current) {
            return true
        }

        current -= item[key]
        return false
    })
}