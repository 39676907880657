import Component from '../core/Component'
import EventBus from '../core/EventBus'

export default class HelpLayer extends Component {
    prepare() {
        EventBus.on('manual:show', this.handleManualShow)
    }

    destroy() {
        EventBus.off('manual:show', this.handleManualShow)
    }

    handleManualShow = () => {
        const layer = Component.getFromElement(this.element.closest('[data-component="Layer"]'))

        try {
            sessionStorage.setItem('showManual', false)
        } catch (e) {}

        if (layer) {
            layer.open()
        }
    }
}