import * as PIXI from 'pixi.js'
import { randomInteger } from '../utils/Math';

const TILE_LENGTH = 53

const PLATFORM_START_WIDTH = 65
const PLATFORM_MEDIUM_WIDTH = 42
const PLATFORM_END_WIDTH = 75
const PLATFORM_HEIGHT = 125
const PLATFORM_BOX_HEIGHT = 67

const DEFAULT_LENGTH = 2

const _defaults = {
    length: 5,
    type: 'light' // dark
}

export default class Platform extends PIXI.Container {
    constructor(options) {
        super()

        this.options = {
            ..._defaults,
            ...options
        }

        this.platformStart = new PIXI.Sprite(PIXI.Texture.from(`platform-start-${this.options.type}`))
        this.platformMiddle = new PIXI.extras.TilingSprite(PIXI.Texture.from(`platform-middle-${this.options.type}`))
        this.platformEnd = new PIXI.Sprite(PIXI.Texture.from(`platform-end-${this.options.type}`))
        this.snowStart = new PIXI.Sprite(PIXI.Texture.from('platform-snow-start'))
        this.snowMiddle = new PIXI.Sprite(PIXI.Texture.from('platform-snow-middle'))
        this.snowEnd = new PIXI.Sprite(PIXI.Texture.from('platform-snow-end'))
        this.snowStart.position.set(-11, 2)
        this.snowEnd.anchor.set(1, 0)
        this.snowMiddle.anchor.set(0.5, 0)

        this.reset()

        this.addChild(this.platformStart)
        this.addChild(this.platformMiddle)
        this.addChild(this.platformEnd)
        this.addChild(this.snowStart)
        this.addChild(this.snowEnd)
        this.addChild(this.snowMiddle)
    }

    get box() {
        return {
            x: this.position.x + 7,
            y: this.position.y + 7,
            width: this.width - 57 * 2,
            height: PLATFORM_BOX_HEIGHT - 7
        }
    }

    setLength(length = DEFAULT_LENGTH) {
        const total = length
        length = Math.max(length, DEFAULT_LENGTH) - DEFAULT_LENGTH
        this.options.length = length

        this.platformMiddle.position.set(PLATFORM_START_WIDTH, 0)
        this.platformMiddle.width = PLATFORM_MEDIUM_WIDTH + length * TILE_LENGTH
        this.platformMiddle.height = PLATFORM_HEIGHT
        this.platformEnd.position.set(PLATFORM_START_WIDTH + PLATFORM_MEDIUM_WIDTH + length * TILE_LENGTH, 0)

        if (total > 6) {
            this.snowEnd.visible = true
            this.snowEnd.position.set(PLATFORM_START_WIDTH + PLATFORM_MEDIUM_WIDTH + length * TILE_LENGTH + PLATFORM_END_WIDTH - 60, 6)
        } else {
            this.snowEnd.visible = false
        }

        if (total > 9) {
            const min = this.snowStart.x + this.snowStart.width
            const max = this.snowEnd.x - this.snowEnd.width
            this.snowMiddle.visible = true
            this.snowMiddle.position.set(min + (max - min)/2, 5)
        } else {
            this.snowMiddle.visible = false
        }
    }

    reset() {
        this.setLength(randomInteger(5, 20))
    }
}