import $ from 'jquery'

export default class LeaderboardManager {
    constructor(engine) {
        this.engine = engine

        this.url = this.engine.app.leaderboardUrl
        this.isUpdating = false

        this.reset()
        this.score = this.engine.score.total
        this.position = 0
        this.queue = []
        this.next = {
            position: '--',
            score: Infinity
        }
        this.request = null
    }

    update(score) {
        this.score = score
        this.check()
    }

    check() {
        if (this.isUpdating) {
            return
        }

        if (this.position === 1) {
            return
        }

        let index = this.leaderboard.findIndex(item => item.score > this.score)

        // can't find in current leaderboard
        if (index === -1) {
            const last = this.leaderboard[this.leaderboard.length -1]

            // last item is first place
            if (last.position === 1) {
                this.position = 1
                this.engine.leaderboard.update(this.position)
            } else { // otherwise get new leaderboard
                this.refresh()
            }
            return
        }

        index--
        let nextPosition = this.position

        if (index === -1) {
            nextPosition = this.leaderboard[0].position + 1
        } else {
            nextPosition = this.leaderboard[index].position
            if (index > 0) {
                this.leaderboard = this.leaderboard.slice(index)
            }
        }

        if (nextPosition !== this.position) {
            this.position = nextPosition
            this.engine.leaderboard.update(this.position)
        }
    }

    reset() {
        this.engine.leaderboard.update('--')
        this.score = 0
        this.position = 0

        if (this.request) {
            this.request.abort()
        }

        this.refresh()
    }

    refresh() {
        this.isUpdating = true
        this.request = $.ajax({
            url: this.url,
            data: {
                score: this.score
            },
            dataType: 'JSON',
            success: this.handleResponse
        })
    }

    end() {
        if (this.request) {
            this.request.abort()
        }
    }

    handleResponse = response => {
        this.leaderboard = response.leaderboard
        if (this.leaderboard.length === 0) {
            this.position = 1
            this.engine.leaderboard.update(this.position)
        }
        this.isUpdating = false
        this.check()
    }
}