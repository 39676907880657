import Platform from './Platform'
import ObjectPool from '../utils/ObjectPool'
import { randomInteger } from '../utils/Math'
import Hist from '../utils/Hist'

export default class PlatformManager {
    constructor(engine) {
        this.engine = engine
        this.pool = new ObjectPool(Platform)
        this.platforms = []

        this.delta = 0
        this.spawnDelay = 60 * 3
    }

    update(delta) {
        for (let i = 0; i < this.platforms.length; i++) {
            const platform = this.platforms[i]

            platform.position.x -= 1 * delta * this.engine.speed

            if (platform.position.x + platform.width < -100) {
                this.removePlatform(i)
                i--
            }
        }

        this.delta += delta * this.engine.speed

        if (this.delta > this.spawnDelay) {
            this.addPlatform()
            this.delta = 0
        }
    }

    addPlatform() {
        const platform = this.pool.getObject()
        this.spawnDelay = randomInteger(400, 2000) + platform.width

        platform.position.set(this.engine.view.options.width + 200, 440)
        this.platforms.push(platform)
        Hist.push({
            type: 'platform:add',
            data: {
                x: platform.position.x,
                y: platform.position.y,
                length: platform.options.length,
                gameDelta: this.engine.gameDelta
            }
        })
        this.engine.view.objects.addChild(platform)
    }

    removePlatform(index) {
        const platform = this.platforms[index]
        this.engine.view.objects.removeChild(platform)
        this.platforms.splice(index, 1)
        this.pool.returnObject(platform)
    }

    destroyAll() {
        this.platforms.forEach(platform => this.engine.view.objects.removeChild(platform))
        this.platforms = []
        this.delta = 0
    }
}