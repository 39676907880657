import Component from '../core/Component'
import EventBus from '../core/EventBus'
import { format } from '../utils/Number'

export default class ScoreLayer extends Component {
    constructor(element) {
        super(element)

        this.ref = {
            coinBronze: null,
            coinSilver: null,
            coinGold: null,
            brick: null,
            total: null,
            resetButton: null
        }
    }

    prepare() {
        EventBus.on('game:over', this.handleGameOver)
        EventBus.on('game:show', this.handleGameShow)

        this.ref.resetButton.addEventListener('click', this.handleResetClick)
    }

    handleResetClick = event => {
        event.target.blur()
        event.preventDefault()
        EventBus.emit('game:reset')
    }

    handleGameOver = data => {
        Object.keys(data)
            .forEach(key => {
                if (this.ref[key]) {
                    this.ref[key].innerText = format(data[key])
                }
            })
    }

    handleGameShow = () => {
        const layer = Component.getFromElement(this.element.closest('[data-component="Layer"]'))

        if (layer) {
            layer.close()
        }
    }
}